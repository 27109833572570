import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "./Table";
import Button from "react-bootstrap/Button";
import CloseButton from "react-bootstrap/CloseButton";
import FormControl from "react-bootstrap/FormControl";
import { Form } from "bootstrap-4-react";

//import {updateSubcategoryName} from "../Utils";

import baseUrl, {
  authentication,
  deductPoints,
  updateFirstName,
  updateLastName,
  updatePoints,
  getGrades,
  bankAverage,
  useModelPerDay,
  minimalDisplay,
  addBankAverage,
  setClass,
  getClasses,
  roundAverage,
  isOhrHachaim,
  showWorkPoints
} from "../Utils";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import { forEach } from "react-bootstrap/cjs/ElementChildren";
import { Helmet } from "react-helmet";

class Poster extends React.Component {
  reset = () => {
    this.setState(
      {
        studentData: [],
        date: "",
        formattedDate: "",
        defaultClassSelectVal: 0,
        classes: [],
      },
      setClass(this)
    );
  };
  boldPoints = true;
  posterClass = "poster"+(isOhrHachaim ? "-1" : "");
  boldWork =false;
  

  constructor(props) {
    super(props);

    if (showWorkPoints) {
      this.boldPoints = false;
      this.boldWork = true;
    }

    this.state = {
      studentData: [],
      defaultClassSelectVal: 0,
      classes: [],
      newSubcategoryOpen: false,
      studentColumns: [
        {
          dataField: "bank_account_number",
          text: (minimalDisplay ? "Account Number" : "Bank account number"),
          sort: true,
          editable: false,
          hidden: false,
          toggle: true,
          headerFormatter:  this.addCloseColumn,
        }
      ]
    };
    
    if (!minimalDisplay) {
    this.state.studentColumns.push(  
      {
        dataField: "points",
        text: isOhrHachaim ? "YEAR TO DATE" : "Points",
        sort: true,
        row: 1,
        editable: true,
        formatter: (cell, row) => {
          if (this.boldPoints && (row.tests != "" || row.retake != "" || row.marks < -4)) {
            return (
              <span
                className={"strikeout"}
                style={{
                  width: "100%",
                  height: "100%",
                  background: "black",
                  padding: "0px",
                }}
              >
                <strong style={{ color: "white" }}>{cell} </strong>
              </span>
            );
          }
          return <span style={{ fontSize: "1 em", fontWeight: "bold" }}>{cell}</span>;
        },
        headerFormatter:  this.addCloseColumn,
      });
    }
    this.state.studentColumns.push(
      {
        dataField: "marks",
        text: isOhrHachaim ? "Points" : "Marks",
        sort: true,
        editable: false,
        headerFormatter:  this.addCloseColumn,
  
      },
      {
        dataField: "tests",
        text: "Missing Tests",
        sort: true,
        editable: false,
        headerFormatter:  this.addCloseColumn,
        classes: 'table-text',
        formatter: (cell, row) => {
          if (this.boldWork) {
            return this.boldedFormat(cell, row)
          } else {
            return cell;
          }
        }
      },
      {
        dataField: "retake",
        text: "Missing Retakes",
        sort: true,
        editable: false,
        classes: 'table-text',
        headerFormatter:  this.addCloseColumn,
        formatter: (cell, row) => {
          if (this.boldWork) {
            return this.boldedFormat(cell, row)
          } else {
            return cell;
          }
        }
      },
    );
      
    if (useModelPerDay)  {
      this.state.studentColumns.push(
        {
          dataField: "model",
          text: "% Behavior",
          sort: true,
          editable: false,
          headerFormatter:  this.addCloseColumn,
          formatter: (cell, row) => {
            return (cell)+"%";
          },
        },
      );
    } else {
      if (!minimalDisplay) {
        this.state.studentColumns.push(
          {
            dataField: "percentOfModel",
            text: "%",
            sort: true,
            editable: false,
            headerFormatter:  this.addCloseColumn,
          }
        );
      }
    }
    if (bankAverage) {
      this.state.studentColumns.push({
        dataField: "average",
        text: "Test Average",
        sort: true,
        editable: false,
        formatter: (cell, row) => {
          return roundAverage(cell);
        },
        headerFormatter:  this.addCloseColumn,
      });
    }
    if (useModelPerDay) {
      this.state.studentColumns.push(
        {
          dataField: 'df1',
          isDummyField: true,
          text: 'Total Average',
          formatter: (cellContent, row) => {
          
            return (
              (isNaN(row.average) || (!row.model)) ? "NA" :
              roundAverage((row.average + row.model) /2)
            );
          },
          headerFormatter:  this.addCloseColumn
        }
      );
    }
    setClass(this);
    getClasses(0, this);
  }
  addCloseColumn = (column, colIndex, { sortElement, filterElement }) => {
          return (
              <div>                        
                {column.text}  
                {sortElement}
                <span><CloseButton onClick={e=> {
                        e.stopPropagation(); 
                        column.hidden = true;
                        this.forceUpdate();
                        }}/></span>
              </div>
          );
    };
    
    boldedFormat = (cell, row) => { 
      if (row.tests != "" || row.retake != "" || row.marks < -4) {
        return (
          <span
            style={{
              width: "100%",
              height: "100%",
              background: "black",
              padding: "0px",
            }}
          >
            <strong style={{ color: "white" }}>{cell} </strong>
          </span>
        );
          }
          return <span>{cell}</span>
      }  

  render() {
    return (
      <div>
        {/*@media print {}*/}
        <Helmet>
          <style>{`
           @media print {
                    .table-bordered td, .table-bordered th {
                    border: 1px solid black !important;
                }}         
                `}</style>
        </Helmet>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Rewards</Breadcrumb.Item>
          <Breadcrumb.Item active>Poster</Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ padding: "15px" }} className={this.posterClass}>
          <div
            className="row"
            style={{ textAlign: "center", paddingBottom: "15px" }}
          >
            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingTop: "10px" }}
            >

          <div
            className="row"
            style={{
              textAlign: "center",
              padding: "15px",
              paddingLeft: "25px",
            }}
          >
            <div className="col-sm-12 noPrint" style={{ paddingTop: "10px" }}>
              <Form.CustomSelect
                lg
                mb="3"
                value={this.state.defaultClassSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    this.setState(
                      {
                        defaultClassSelectVal: v.target.value,
                        studentData: [],
                      }
                    );

                    setClass(this, v.target.value)
                  }
                }}
              >
                <option value="0">All Classes</option>
                {this.state.classes.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.class}
                  </option>
                ))}
              </Form.CustomSelect>
            </div>
          </div>
          </div>

            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingRight: "30px", paddingBottom: "10px" }}
            >

              {/* <Button style={{float:"right"}} onClick={()=>{this.submit()}}>
                                    Save
                                </Button>*/}
            </div>
          </div>

          {this.state.studentData != undefined ? (
            <Table
              defaultSorted={[
                { dataField: "bank_account_number", order: "asc" },
              ]}
              pagination={false}
              delete={false}
              editFunc={(oldValue, newValue, row, column) => {
                {
                  if (oldValue != newValue) {
                    if (column.dataField == "points") {
                      let points = newValue - oldValue;
                      deductPoints(row.id, points);
                    }
                    if (column.dataField == "first_name") {
                      updateFirstName(row.id, newValue);
                    }
                    if (column.dataField == "last_name") {
                      updateLastName(row.id, newValue);
                    }
                  }
                }
              }}
              columns={this.state.studentColumns}
              data={this.state.studentData}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default Poster;
