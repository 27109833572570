import React from "react";

import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Collapsible from "react-collapsible";
import { FaChevronDown, FaCheck, FaTimes } from "react-icons/fa";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import { Type } from "react-bootstrap-table2-editor";

class StudentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      class: "",
      points: "",
      date: this.props.history.location.state.date,
      topic: this.props.history.location.state.topic,
      lessonId: this.props.history.location.state.lessonId,
      studentData: [],
      subcategoryData: undefined,
    };
    this.getLesson();
    //this.getPoints()
  }
  studentColumns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "absent",
      formatter: (cell, row, rowIndex) => {
        if (cell == true) {
          return (
            <div style={{ textAlign: "center" }}>
              <FaCheck color="red" size="1.5em" />
            </div>
          );
        }
        return <div></div>;
      },
      text: "Absent",
      sort: true,
      editable: false,
    },
    {
      dataField: "work",
      formatter: (cell, row, rowIndex) => {
        if (cell == true) {
          return (
            <div style={{ textAlign: "center" }}>
              <FaCheck color="green" size="1.5em" />
            </div>
          );
        } else if (row.absent == false) {
          return (
            <div style={{ textAlign: "center" }}>
              <FaTimes color="red" size="1.5em" />
            </div>
          );
        }
        return <div></div>;
      },
      text: "Work",
      sort: true,

      editable: false,
    },
    {
      dataField: "point",
      formatter: (cell, row, rowIndex) => {
        if (cell == true) {
          return (
            <div style={{ textAlign: "center" }}>
              <FaCheck color="green" size="1.5em" />
            </div>
          );
        }
        return <div></div>;
      },
      text: "Point",
      sort: true,
      editable: false,
    },
    {
      dataField: "late",
      formatter: (cell, row, rowIndex) => {
        if (cell == false && row.absent == false) {
          return (
            <div style={{ textAlign: "center" }}>
              <FaCheck color="green" size="1.5em" />
            </div>
          );
        }
        if (row.absent == false) {
          return (
            <div style={{ textAlign: "center" }}>
              <FaTimes color="red" size="1.5em" />
            </div>
          );
        }
        return <div></div>;
      },
      text: "On Time",
      sort: true,
      editable: false,
    },
    {
      dataField: "attention",
      formatter: (cell, row, rowIndex) => {
        if (cell == "1") {
          return (
            <div style={{ textAlign: "center" }}>
              <FaCheck color="green" size="1.5em" />
            </div>
          );
        } else if (cell == "0") {
          return (
            <div style={{ textAlign: "center" }}>
              <FaTimes color="red" size="1.5em" />
            </div>
          );
        }
        return <div></div>;
      },
      text: "Attention",
      sort: true,
      editable: false,
    },
    {
      dataField: "note",
      text: "Notes",
      editor: {
        type: Type.TEXTAREA,
      },
    },
  ];

  getLesson() {
    let formData = new FormData();
    formData.append("action", "get lesson");
    formData.append("id", this.state.lessonId);
    var url = "http://ohrhatorah.appsenses.com/lesson.php";

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
        });
        this.setState({ studentData: res, class: res[0].class });
      });
  }

  getPoints() {
    let formData = new FormData();
    formData.append("action", "get points");
    formData.append("id", this.state.studentId);
    var url = "http://ohrhatorah.appsenses.com/bank.php";

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({ points: res[0].points });
      });
  }

  defaultSorted = [
    {
      dataField: "date", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="lessons">Lessons</Breadcrumb.Item>
          <Breadcrumb.Item active>{this.state.date}</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ textAlign: "center", padding: "15px" }}>
          <div class="row">
            <div class="col-sm-3" style={{ paddingTop: "10px" }}>
              <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Date
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  aria-label="Channel Name"
                  aria-describedby="inputGroup-sizing-default"
                  value={this.state.date}
                  // onChange={(v)=>{this.setState({channelName:v.target.value})}}
                />
              </InputGroup>
            </div>
            <div className="col-sm-2" style={{ paddingTop: "10px" }}>
              <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Class
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  aria-label="Channel Name"
                  aria-describedby="inputGroup-sizing-default"
                  value={this.state.class}
                  // onChange={(v)=>{this.setState({channelName:v.target.value})}}
                />
              </InputGroup>
            </div>
            <div
              className="col-sm-7"
              style={{ paddingTop: "10px", paddingRight: "10px" }}
            >
              <InputGroup className="mb-3" style={{ paddingRight: "10px" }}>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Topic
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  aria-label="Channel Image"
                  aria-describedby="inputGroup-sizing-default"
                  value={this.state.topic}
                  /*onChange={(v) => {
                                    this.setState({channelImage: v.target.value})
                                }}*/
                />
              </InputGroup>
            </div>
            {/* <div className="col-sm-2" style={{paddingTop: "10px",paddingRight:"10px"}}>
                        <InputGroup className="mb-3" style={{paddingRight:"10px"}}>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">Points</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl readOnly
                                         aria-label="Channel Image"
                                         aria-describedby="inputGroup-sizing-default"
                                         value={this.state.points}
                                onChange={(v) => {
                                    this.setState({channelImage: v.target.value})
                                }}

                            />
                        </InputGroup>
                    </div>*/}
          </div>
          <div className="Collapsible">
            {/*<div style={{paddingTop:'10px',paddingBottom:'10px'}}>
                        <Button onClick={this.handleSubcategoryShow}>
                            Add subcategory
                        </Button>
                    </div>*/}
            {
              <Table
                defaultSorted={this.defaultSorted}
                ref={(n) => (this.existingSubcategoryTableRef = n)}
                columns={this.studentColumns}
                reload={this.state.reload}
                data={this.state.studentData}
                deleteClick={this.deleteSubFromChannel}
                editFunc={(oldValue, newValue, row, column) => {
                  if (oldValue != newValue) {
                    if (column.dataField == "attention") {
                      let attention;
                      if (newValue == "yes") {
                        attention = 1;
                      }
                      if (newValue == "no") {
                        attention = 0;
                      }

                      let formData = new FormData();
                      formData.append("action", "edit attention");
                      formData.append("id", row.id);
                      formData.append("attention", attention);
                      formData.append("studentId", this.state.studentId);
                      var url = "http://ohrhatorah.appsenses.com/lesson.php";

                      fetch(url, {
                        method: "POST",
                        body: formData,
                      })
                        .then((response) => {
                          return response.json();
                        })
                        .then((res) => {
                          res.map((item) => {
                            item.id = parseInt(item.id);
                          });
                          this.setState({ studentData: res });
                        });
                    } else if (column.dataField == "note") {
                      let formData = new FormData();
                      formData.append("action", "edit note");
                      formData.append("id", row.id);
                      formData.append("note", newValue);
                      formData.append("studentId", this.state.studentId);
                      var url = "http://ohrhatorah.appsenses.com/lesson.php";

                      fetch(url, {
                        method: "POST",
                        body: formData,
                      });
                    }
                  }
                }}
                rowEvents={{
                  onClick: (e, row, rowIndex) => {
                    console.log(e);
                    if (e.type == "click") {
                      //this.props.stateFunc(row.image)
                      this.props.history.push({
                        pathname: `/student-details/${row.student_id}`,
                        state: {
                          class: row.class,
                          studentId: row.student_id,
                          firstName: row.first_name,
                          lastName: row.last_name,
                          classId: row.class_id,
                        },
                      });
                    }
                  },
                }}
              />
            }
          </div>

          {/*<Collapsible lazyRender={true} trigger={<CollapsibleText text="Loose videos in this Channel"/>} onOpening={()=>{}} style={{background:"black"}}>
                    {this.state.videoData!=undefined?  <Table columns={this.videoColumns} data={this.state.videoData}/>:""}

                </Collapsible>*/}
        </div>
      </div>
    );
  }
}
const CollapsibleText = (props) => {
  {
    /*className='col-sm-3 col-lg-2'*/
  }
  {
    /*className='col-sm-9 col-lg-10'*/
  }
  return (
    <span style={{ paddingLeft: "15px" }} className="row">
      <span style={{ width: "250px" }}> {props.text}</span>{" "}
      <span>
        <FaChevronDown />
      </span>
    </span>
  );
};
export default StudentDetails;
