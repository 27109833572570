import React, { Component } from 'react';
import baseUrl, {authentication} from '../Utils'
import Button from "react-bootstrap/Button";

class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCreating: false, 
            isEditing: null,
            termName: '', 
            termEnd: new Date(),
            termStart: new Date(),
            editTermName: '', 
            editTermEnd: new Date(),
            editTermStart: new Date(),
            terms: []
            // terms: [{id:1, name: 'winter', start_date: new Date(), end_date: new Date()}, {id:2,name: 'summer', start_date: new Date(), end_date: new Date()}]
        }
        this.getTerms()
    }

    getTerms(){
        fetch(`${baseUrl}/get-all-terms`, {
            headers: {
              Authorization: authentication,
            }
          }).then(t => t.json())
            .then(t => {console.log(t); this.setState({terms: t})}).catch(e => console.error(`OOOPS! ${e}`));
    }

    saveTerm(id){
        const url = id ? `update-term` : 'create-term'
        const {isCreating, termName, termStart, termEnd, isEditing, editTermName, editTermStart, editTermEnd} = this.state
        console.log(isCreating, termName, termStart, termEnd, isEditing, editTermName, editTermStart, editTermEnd)
        if((!termName && !id) || (!editTermName && id)) return alert('You must have a Term Name!')
        if(id){
            this.setState({isEditing: null,  editTermName: '', editTermStart: new Date(), editTermEnd: new Date()})
        }else{
            this.setState({isCreating: false,  termName: '', termStart: new Date(), termEnd: new Date()})
        }

        const formData = new FormData()
        formData.append('term', JSON.stringify({id, name: id ? editTermName : termName, start_date: id ? editTermStart : termStart, end_date: id ? editTermEnd : termEnd}))
        fetch(`${baseUrl}/${url}`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: authentication,
            }
            }).then(t => t.text())
              .then(t => {console.log(t); this.getTerms()}).catch(e => console.error(`OOOPS! ${e}`));
    }

    deleteTerm(id, name, start_date, end_date){
        const shouldDelete = window.confirm(`Are you sure you want to Delete this term? Name: ${name}. From ${start_date}. To ${end_date}`)
        if(!shouldDelete) return
        fetch(`${baseUrl}/delete-term/${id}`, {
            headers: {
                Authorization: authentication,
            }
            }).then(t => t.text())
              .then(t => {console.log(t); this.getTerms()}).catch(e => console.error(`OOOPS! ${e}`));
    }

    // componentWillMount() {

    // }

    // componentDidMount() {
    // }

    // componentWillReceiveProps(nextProps) {

    // }

    // shouldComponentUpdate(nextProps, nextState) {

    // }

    // componentWillUpdate(nextProps, nextState) {

    // }

    // componentDidUpdate(prevProps, prevState) {

    // }

    // componentWillUnmount() {

    // }

    render() {
        const {isCreating, termName, termStart, termEnd, isEditing, editTermName, editTermStart, editTermEnd, terms} = this.state
        return (
            <div style={{width: '75%', margin: 'auto'}}>
                <Button style={{margin: 10}} onClick={() => this.setState({isCreating: true})}>Create New Term</Button>
                <div style={{marginBottom: 100}}>
                {isCreating && (
                    <div>
                        <input value={termName} onChange={e => this.setState({termName: e.target.value})} placeholder='Name' />
                        <input type="date" value={termStart.toISOString().split('T')[0] } onChange={e => {console.log(e.target.value);
                            if(!e.target.value) return;
                            this.setState({termStart: new Date(e.target.value)})}}  placeholder='Start Date' />
                        <input type="date" value={termEnd.toISOString().split('T')[0]} onChange={e => {if(!e.target.value) return;
                            this.setState({termEnd: new Date(e.target.value)})}}  placeholder='End Date' />
                        <Button onClick={() => this.saveTerm()}>Save</Button>
                        <Button onClick={() => this.setState({isCreating: false})}>Cancel</Button>
                    </div>
                )}
                </div>
                <div style={{display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', width: '80%', margin: 'auto'}}>
                {terms.map(t => {
                    const {id, name, start_date, end_date} = t
                    const start = new Date(start_date);
                    const end = new Date(end_date);
                    return <div key={id} style={{width: `${100 / terms.length}%`, height: '100%', textAlign: 'center'}}>
                    {isEditing !== id ? (
                            <><div style={{color: 'blue', textTranform: 'uppercase', fontWeight: 'bold'}}>{name}</div>
                            <div>{new Date(start.setDate(start.getDate() + 1)).toDateString()}</div>
                            <div>{new Date(end.setDate(end.getDate() + 1)).toDateString()}</div>
                                <Button onClick={() => this.setState({isEditing: id, editTermName: name, editTermStart: start, editTermEnd: end})}>Edit</Button>
                                <Button onClick={() => this.deleteTerm(id, name, start_date, end_date)}>Delete</Button></>
                        ) : (
                            <><div><input value={editTermName} onChange={e => this.setState({editTermName: e.target.value})} placeholder='Name' /></div>
                            <div><input type="date" value={editTermStart.toISOString().split('T')[0] } onChange={e => {if(!e.target.value) return;
                                this.setState({editTermStart: new Date(e.target.value)})}}  placeholder='Start Date' /></div>
                            <div><input type="date" value={editTermEnd.toISOString().split('T')[0]} onChange={e => {if(!e.target.value) return;
                                this.setState({editTermEnd: new Date(e.target.value)})}}  placeholder='End Date' /></div>
                            <Button onClick={() => this.saveTerm(id)}>Save</Button>
                            <Button onClick={() => this.setState({isEditing: null})}>Cancel</Button></>
                    )
                }
            </div>
    })}
            </div>
            </div>
        );
    }
}

export default Terms;