import React, { useEffect, useState, useRef } from "react";
import Table from "./Table";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Type } from "react-bootstrap-table2-editor";
import { FaCheck } from "react-icons/fa";
import "../GlobalVariables";
import baseUrl, {
  authentication,
} from "./../Utils";
import "../App.css";
import moment from "moment-timezone";

const OldNotes = ({recent, current}) => {
  const [notes, setNotes] = useState([])
  const [categories, setCategories] = useState([])
  let existingSubcategoryTableRef  = useRef()
  
  useEffect(() => {
    getNotes()
    getCategories()
  }, [])
  

  const getCategories = () => {
    fetch(`${baseUrl}/categories`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        console.log("🚀 ~ file: Settings.js ~ line 228 ~ Settings ~ .then ~ res", res)
        setCategories(res.map(c => ({ value: c.id, label: c.name })))
      })
  }

  const principalNotesColumns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
      editable: false,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
      editable: false,
    },
    {
      dataField: "time",
      text: "Time recorded",
      sort: true,
      editable: false,
      sortFunc: (a, b, order) => {
        if (order === "asc") {
          return Date.parse(a) - Date.parse(b);
        } else if (order === "desc") {
          return Date.parse(b) - Date.parse(a);
        }
      }
    },
    {
      dataField: "class",
      text: "Class",
      sort: true,
      editable: false,
    },
    {
      dataField: "category_name",
      text: "Category",
      sort: false,
      editable: true,
      editor: {
        type: Type.SELECT,
        getOptions: () => {
          return categories;
        },
      },
    },
    {
      dataField: "large",
      text: "Incident",
      sort: false,
      editable: true,
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
    {
      dataField: "consequence",
      text: "Consequence",
      sort: false,
      editable: true,
      editor: {
        type: Type.TEXTAREA,
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
    {
      dataField: "consequenceFulfilled",
      text: "Consequence Fulfilled?",
      sort: false,
      editable: true,
      editor: {
        type: Type.CHECKBOX,
      },
      headerStyle: () => {
        return { width: "3%", textAlign: "center" };
      },
    },
    {
      dataField: "parentsContacted",
      text: "Parents Contacted?",
      sort: false,
      editable: true,
      editor: {
        type: Type.CHECKBOX,
      },
      headerStyle: () => {
        return { width: "3%", textAlign: "center" };
      },
    },
    {
      dataField: "comments",
      text: "Comments",
      sort: false,
      editable: false,
      formatter: (comments) => comments.map(({ displayName, date, text }) => <><b><i>{displayName ? `${displayName}: ` : ''}</i></b> <b>{date}</b><br /> {text}{text && <hr />}</>),
    },
    {
      dataField: "addcomment",
      text: "Add Comment",
      headerStyle: (column, colIndex) => {
        return { width: "6%", textAlign: "center" };
      },
      editor: {
        type: Type.TEXTAREA,
      },
      sort: false,
      editable: true,
    },
  ];

  const getNotes = () => {
    fetch(`${baseUrl}/get-all-notes${recent ? `?recent=${true}` : current ? `?current=${true}` : ''}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        const noteIdsObj = res.reduce((pre, cur) => ({
          ...pre,
          [cur.note_id]: (pre[cur.note_id] || []).concat({
            ...cur,
            addcomment: '',
            consequenceFulfilled: cur.consequenceFulfilled == 1 ? <FaCheck color="green" size="1.5em" /> : <input type="checkbox" />,
            parentsContacted: cur.parentsContacted == 1 ? <FaCheck color="green" size="1.5em" /> : <input type="checkbox" />,
            time: moment.utc(cur.note_time).local().format("MM/DD/YYYY"),
            comment: { displayName: cur.displayName || '', date: cur.comment_date ? new Date(cur.comment_date).toLocaleString() : '', text: cur.comment || '' },
          })
        }), {});
        console.log("🚀 ~ file: OldNotes.js ~ line 79 ~ OldNotes ~ noteIdsObj ~ noteIdsObj", noteIdsObj)
        const modifiedResults = Object.keys(noteIdsObj).map(noteId => {
          const thisIdsComments = noteIdsObj[noteId];
          return {
            ...thisIdsComments[0],
            id: noteId,
            comments: thisIdsComments.reduce((pre, cur) => pre.concat(cur.comment), []),
          }
        });
        console.log("🚀 ~ file: OldNotes.js ~ line 88 ~ OldNotes ~ modifiedResults ~ modifiedResults", modifiedResults)
        setNotes(modifiedResults)
      });
  }

  const resolveNotes = (selected) => {
    const confirmed = window.confirm('Are you sure would like to resolve the selected notes - which will delete them from the current notes page?');
    if (!confirmed) return;

    const formData = new FormData()
    formData.append('resolved_ids', `(${selected.join(',')})`)

    fetch(`${baseUrl}/resolve-notes`, {
      method: 'POST',
      headers: {
        Authorization: authentication,
      },
      body: formData,
    })
      .then(getNotes)
    return true;
  }

  const addComment = (comment, noteId) => {
    const { id } = JSON.parse(localStorage.getItem('userInfo') || '{}');

    const formData = new FormData()
    formData.append('comment', comment)
    formData.append('note_id', noteId)
    formData.append('userid', id)

    fetch(`${baseUrl}/add-comment`, {
      method: 'POST',
      headers: {
        Authorization: authentication,
      },
      body: formData,
    })
      .then(getNotes)
    return true;
  }

  const editNote = (newvalue, noteId, dataField) => {
    if (!newvalue) return
    console.log("🚀 ~ file: OldNotes.js ~ line 166 ~ OldNotes ~ editNote ~ newvalue, noteId, dataField", newvalue, noteId, dataField)
    const formData = new FormData()
    formData.append('datafield', dataField === 'category_name' ? 'category_id' : dataField)
    formData.append('newvalue', dataField === 'consequenceFulfilled' || dataField === 'parentsContacted' ? newvalue === 'on' ? 1 : 0 : newvalue)
    formData.append('note_id', noteId)

    fetch(`${baseUrl}/edit-note`, {
      method: 'POST',
      headers: {
        Authorization: authentication,
      },
      body: formData,
    })
      .then(getNotes)
    return true;
  }

    return (
      <div>
        <Breadcrumb
          listProps={{
            style: { marginBlockEnd: "1em" },
          }}
        >
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>{recent ? 'Recent' : current ? 'Current' : 'Old'} Notes</Breadcrumb.Item>
        </Breadcrumb>

        {notes.length > 0 && (
          <div style={{ paddingTop: "15px" }}>
            <div style={{ paddingTop: "10px" }}>
              {
                <Table
                  deleteText={'Resolve'}
                  editable
                  editFunc={(oldValue, newValue, row, column) => {
                    if (column.text === "Add Comment") {
                      addComment(newValue, row.id, column);
                    }
                    else {
                      let theValue = newValue;
                      if (column.text === "Consequence Fulfilled" || column.text === "Parents Contacted") {
                        theValue = newValue === 'on';
                      }
                      editNote(theValue, row.id, column.dataField);
                    }
                  }}
                  deleteClick={resolveNotes}
                  defaultSorted={[
                    {
                      dataField: "time", // if dataField is not match to any column you defined, it will be ignored.
                      order: "desc", // desc or asc
                    },
                  ]}
                  switch={false}
                  ref={(n) => (existingSubcategoryTableRef = n)}
                  columns={principalNotesColumns}
                  data={notes}
                />
              }
            </div>
          </div>
        )
        }
      </div>
    )
  }

export default OldNotes;
