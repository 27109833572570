import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from 'react';
import baseUrl, { authentication} from "../Utils";

export default function AdvancedBehaviorSettings(){
    const [submiting, setSubmiting] = useState(false)
    const [settings, setSettings] = useState({})
    // const [settings, setSettings] = useState({1: {setting: '1', value: 'a'}, 2: {setting: '2', value: 'b'}, 3: {setting: '3', value: 'c'}, 4: {setting: '4', value: 'd'}, 5: {setting: '5', value: 'e'}, 6: {setting: '6', value: 'f'}, 7: {setting: '7', value: 'g'}, 8: {setting: '8', value: 'h'}})

    useEffect(() => {
        getSettings()
    }, [])

    const validateSettings = () => (Object.keys(settings).every(s => {
            const [set, setEnd] = s.split('_');
            if((setEnd === 'low' || setEnd === 'high') && +settings[`${set}_low`].value >= +settings[`${set}_high`].value){
                return alert(`You can not have ${set}_low greater or equal to ${set}_high`);
            }
            return true;
        }))

    const getSettings = () => {
        fetch(`${baseUrl}/settings`,{
            headers: {
                Authorization: authentication,
              }
            })
            .then(r => r.json())
            .then(data => {console.log(data);                 
                setSettings(data.reduce((pre, cur) => {
                    let set = cur.setting.split('_')
                    const theSetting = set[0] === 'marks' ? set.map((b,i) => !i ? 'behavior' : `_${b}`).join('') :
                    cur.setting === 'late_high' ? 'late_default' : cur.setting;
                    return ({...pre, [set[0]]: {...pre[set[0]], [theSetting]: cur}})
                }, {}))
            })
            .catch(err => console.error(err))
    }

    const saveSettings = () => {
        if (submiting || !validateSettings()) return
            setSubmiting(true);
            let formData = new FormData();
            formData.append("settings", JSON.stringify(Object.keys(settings).flatMap(category => Object.keys(settings[category]).map(innerSetting => settings[category][innerSetting]))));
                fetch(`${baseUrl}/save-settings`, {
                    method: "POST",
                    body: formData,
                    headers: {
                    Authorization: authentication,
                    },
                })
                    .then((response) => response.text())
                    .then((text) => {
                    if (text == "update successful") {
                        getSettings();
                        console.log("successful", text)
                    } else {
                        alert(text);
                    }
                    })
                    .catch(err => console.error(err));
            setSubmiting(false);
            }

    return (<>
            <h2 style={{textAlign: 'center', marginBottom: 70}}>Advanced Behavior Settings</h2>
            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '80%', margin: 'auto'}}>
                    {Object.keys(settings).map((category, index) => (
                        <div key={index} style={{border: 'solid 3px red'}}>
                            <h3 style={{textAlign: 'center', color: 'blue', textTransform: 'uppercase'}}>{category === 'marks' ? 'BEHAVIOR' : category === 'late' || category === 'work' ? category : ''}</h3>
                        {Object.keys(settings[category]).map(s => {
                            const {value, id} = settings[category][s]
                            return (
                            <div key={id} style={{margin: 50}}>
                                <h6 style={{textAlign: 'center', textTransform: 'uppercase'}}>{s.replaceAll('_', ' ')}</h6>
                            <input style={{border: 'solid 5px blue', textAlign: 'center'}} value={value} onChange={e => setSettings({...settings, [category]: {...settings[category], [s]: {id, setting: s, value: e.target.value}}})}/>
                            </div>
                    )
                        })}
                        </div>
                    ))}
            </div>
            <Button style={{margin: '50px 0 50px 45%'}} onClick={saveSettings}>Update Settings</Button>
        </>)

}