import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import { authentication, createChannel } from "../Utils";
import Select from "react-select";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import baseUrl from "../Utils";

let items = [];

class newClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      className: "",
      grade: "",
      room: "",
    };
  }

  open = () => {
    this.setState({
      isDialogOpen: true,
    });
  };

  componentWillReceiveProps(nextProps) {
    switch (nextProps.isDialogOpen) {
      case true: {
        this.setState({
          isDialogOpen: true,
        });
        break;
      }
      case false: {
        this.setState({
          isDialogOpen: false,
        });
        break;
      }
    }
    items = nextProps.possibleGrades;

    // for (const [index, value] of nextProps.possibleGrades.entries()) {
    // items.push(<option key={value.id} >{value.name}</option>)
    // }
  }

  uploadNewClass = () => {
    if (this.state.className != "" && this.state.grade != "") {
      fetch(
        `${baseUrl}/class/add/${this.state.grade}/${this.state.className}${
          this.state.room != "" ? "?room=" + this.state.room : ""
        }`,
        {
          headers: {
            Authorization: authentication,
          },
        }
      )
        .then((response) => response.text())

        .then((text) => {
          if (text == "successful") {
            this.props.closeFunction();
            this.props.afterSaveFunction();
          }
        });

      //}
    }
  };

  render() {
    return (
      <Modal
        show={this.state.isDialogOpen}
        onHide={this.handleSubcategoryClose}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>New Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NotificationContainer />
          <div style={{ textAlign: "center" }}>
            <InputGroup
              className="mb-3 col-sm-12"
              style={{ paddingTop: "10px" }}
            >
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroup-sizing-default">
                  Class Name
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Channel Name"
                aria-describedby="inputGroup-sizing-default"
                value={this.state.className}
                onChange={(v) => {
                  this.setState({ className: v.target.value });
                }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 col-sm-12"
              style={{ paddingTop: "10px" }}
            >
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroup-sizing-default">
                  Room
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Channel Name"
                aria-describedby="inputGroup-sizing-default"
                value={this.state.room}
                onChange={(v) => {
                  this.setState({ room: v.target.value });
                }}
              />
            </InputGroup>
            <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Grade</Form.Label>
                <Select
                  //  value={grade}
                  onChange={(selected) => {
                    this.setState({ grade: selected.value });
                  }}
                  options={items}
                />
                {/* <InputGroup className="mb-3 col-sm-12" >
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-default">Grade</InputGroup.Text>
                        </InputGroup.Prepend> */}
                {/* <FormControl
                            aria-label="Channel Image"
                            aria-describedby="inputGroup-sizing-default"
                            value={this.state.channelImage}
                            onChange={(v) => {
                                this.setState({channelImage: v.target.value})
                            }}

                        /> */}

                {/* <Form.Control as="select">
        {items}
      <option>1</option>
      <option>2</option>
      <option>3</option>
      <option>4</option>
      <option>5</option>
    </Form.Control> */}
              </Form.Group>
            </div>
            {/* </InputGroup> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              this.props.closeFunction();
              this.setState({ channelName: "" });
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (NotificationManager.listNotify.length == 0) {
                if (this.state.className == "" || this.state.grade == "") {
                  let e = "";
                  if (this.state.className == "") {
                    e = "Please enter a class name.";
                  }
                  if (this.state.grade == "") {
                    e = "Please enter a grade.";
                  }
                  NotificationManager.error(e, "", 5000, () => {
                    // alert(this.state.grade);
                    // console.log(this.state.grade)
                  });
                } else {
                  this.uploadNewClass();
                }
              }
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

newClass.propTypes = {};

export default newClass;
