import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar";
import DelinquentTest from "./components/DelinquentTest";
import { BrowserRouter as Router, Route } from "react-router-dom";
import StudentDetails from "./components/StudentDetails";
import ClassDetails from "./components/ClassDetails";

import baseUrl, {
  authentication,
  isDev,
  hasShagDay,
  newPhoneNumb,
} from "./Utils";

import NewLesson from "./components/NewLesson";
import Students from "./components/Students";
import NewNote from "./components/NewNote";
import OldNotes from "./components/OldNotes";
import Lessons from "./components/Lessons";
import LessonDetails from "./components/LessonDetails";
import Tests from "./components/Tests";
import NewTest from "./components/NewTest";
import TestDetails from "./components/TestDetails";
import Login from "./components/Login";
import SeatingChart from "./components/SeatingChart";
import cookie from "react-cookies";
import Grades from "./components/Grades";
import Terms from "./components/Terms";
import { IDCard } from "./components/IDCard";
import GradeDetails from "./components/GradeDetails";
import NewBehavior from "./components/NewBehavior";
import SHAGNewBehavior from "./components/SHAGNewBehavior";
import Dashboard from "./components/Dashboard";
import Settings from "./components/Settings";
import AdvancedBehaviorSettings from "./components/AdvancedBehaviorSettings";
import AdvancedGradeSettings from "./components/AdvancedGradeSettings";
import AdvancedTestSettings from "./components/AdvancedTestSettings";
import AdvancedMarksResetDates from "./components/AdvancedMarksResetDates";
import WeekBehavior from "./components/WeekBehavior";
import NewReportCardComments from "./components/NewReportCardComments";
import Redeem from "./components/Redeem";
import Bank from "./components/Bank";
import Poster from "./components/Poster";
import Marquee from "./components/Marquee";
import NewReportCardBehavior from "./components/NewReportCardBehavior";
import Cashier from "./components/Cashier";
import CanteenEmployees from "./components/CanteenEmployees";
import SalaryEmployees from "./components/SalaryEmployees";
import Deposit from "./components/Deposit";
// import ClassReportcards  from "./components/ClassReportcards";
import Tzedaka from "./components/Tzedaka";
import TzedakaAddress from "./components/TzedakaAddress";
import ClassReportcards from "./components/ClassReportcards";
import ScannerUpload from "./components/ScannerUpload";
import ItemBarcodes from "./components/ItemBarcodes";
import HonorRole from "./components/HonorRole";
import StudentBarcodes from "./components/StudentBarcodes";
import PhoneNumbers from "./components/PhoneNumbers";
import EditUser from "./components/EditUser";
import ResetBankPoint from "./components/ResetBankPoints";

require("es6-promise").polyfill();
require("isomorphic-fetch");

class Home extends React.Component {
  render() {
    this.props.history.push({
      pathname: "/channel-details",
    });
    return <h2>Home</h2>;
  }
}

class App extends React.Component {
  state;

  constructor(props) {
    super(props);

    // this.getAllSubcategories()

    this.state = {
      loggedIn:
        cookie.load("username") == "yosef" ||
        cookie.load("username") == "guest",
    };
    console.log(localStorage.getItem("authentication"));
    // this.checkLoggedIn()
    this.forgive();
    this.weeklyPointGiveaway();
  }

  setChannel(val) {
    this.setState({ channelImage: val });
  }
  forgive() {
    fetch(`${baseUrl}/forgive`, {
      headers: {
        Authorization: authentication,
      },
    });
  }
  weeklyPointGiveaway() {
    fetch(`${baseUrl}/calculate-weeks-points`, {
      headers: {
        Authorization: authentication,
      },
    });
  }
  checkLoggedIn() {
    var url = "http://ohrhatorah.appsenses.com/login.php";
    url = baseUrl + "/students";

    fetch(url, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.text();
      })
      .then((res) => {
        if (res == "loggedin") {
          // document.cookie = "username="+this.state.username;

          this.setState({ loggedIn: true });
        } else {
          // document.cookie = "username="+this.state.username;

          this.setState({ loggedIn: false });
        }
      });
  }

  showNav() {
    const url = window.location.pathname;
    if (url === '/marquee') {
      return false;
    } else  {
      return true;
    }
  }

  render() {
    // const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
    //  console.log(useCookies('cookie-name').getAll())
    //if (this.state.loggedIn == false)
    if (
      this.state.loggedIn == false &&
      !localStorage.getItem("authentication")
    ) {
      //alert(authentication)
      return (
        <Login
          callback={() => {
            this.setState({ loggedIn: true });
          }}
        />
      );
    } else {
      return (
        <div>
          <Router>
            {this.showNav() &&
            <Route path="/" component={Navbar} />
            }
            <Route path="/grades" component={Grades}></Route>
            <Route path="/grade-details" component={GradeDetails}></Route>
            <Route path="/new-lesson" component={NewLesson}></Route>
            <Route
              path="/new-day"
              component={hasShagDay ? SHAGNewBehavior : NewBehavior}
            ></Route>
            {isDev && (
              <Route path="/shag-new-day" component={SHAGNewBehavior}></Route>
            )}
            <Route path="/behavior" component={WeekBehavior}></Route>
            <Route path="/lessons" component={Lessons}></Route>
            <Route path="/students" component={Students}></Route>
            <Route path="/class-details" component={ClassDetails}></Route>
            <Route path="/lesson-details" component={LessonDetails}></Route>
            <Route path="/tests" component={Tests}></Route>
            <Route path="/new-test" component={NewTest}></Route>
            <Route path="/test-details" component={TestDetails}></Route>
            <Route path="/seating" component={SeatingChart}></Route>
            <Route path="/delinquent-tests" component={DelinquentTest}></Route>
            <Route
              path="/student-details"
              render={(props) => (
                <StudentDetails {...props} channelImage={this.state} />
              )}
            />
            <Route path="/subcategory-details" component={ClassDetails} />
            <Route path="/home" component={Dashboard} />
            <Route path="/settings" component={Settings} />
            <Route
              path="/advanced-behavior-settings"
              component={AdvancedBehaviorSettings}
            />
            <Route
              path="/advanced-grade-settings"
              component={AdvancedGradeSettings}
            />
            <Route
              path="/advanced-test-settings/:id"
              component={AdvancedTestSettings}
            />
            <Route
              path="/advanced-marks-reset-dates"
              component={AdvancedMarksResetDates}
            /> 
            <Route
              path="/reset-bank-points"
              component={ResetBankPoint}
            /> 
            <Route path="/terms" component={Terms} />
            <Route path="/idcard" component={IDCard} />
            <Route path="/comments" component={NewReportCardComments} />
            <Route
              path="/reportcard-behavior"
              component={NewReportCardBehavior}
            />
            <Route path="/reportcards" component={ClassReportcards} />
            <Route path="/redeem" component={Redeem} />
            <Route path="/bank" component={Bank} />
            <Route path="/poster" component={Poster} />
            <Route path="/marquee" component={Marquee} />
            <Route path="/cashier" component={Cashier} />
            <Route path="/login" component={Login} />
            <Route path="/canteen-employees" component={CanteenEmployees} />
            <Route path="/salary-employees" component={SalaryEmployees} />
            {!newPhoneNumb && <Route path="/tzedaka" component={Tzedaka} />}
            {newPhoneNumb && (
              <Route path="/tzedaka" component={TzedakaAddress} />
            )}
            <Route path="/scanner-upload" component={ScannerUpload} />
            <Route path="/item-barcodes" component={ItemBarcodes} />
            <Route path="/honor-role" component={HonorRole} />
            <Route path="/student-barcodes" component={StudentBarcodes} />
            <Route path="/deposit" component={Deposit} />
            <Route path="/student-note/:id" component={NewNote} />
            <Route path="/notes" component={OldNotes} />
            <Route
              path="/recent-notes"
              render={() => <OldNotes recent={true} />}
            />
            <Route
              path="/current-notes"
              render={() => <OldNotes current={true} />}
            />
            <Route path="/new-note" component={NewNote} />
            {newPhoneNumb && (
              <Route path="/phone-numbers" component={PhoneNumbers} />
            )}
            {/* <Route exact path="/terms" component={Terms}></Route> */}
            <Route exact path="/edit-user" component={EditUser}></Route>
            <Route exact path="/" component={Grades}></Route>
          </Router>
        </div>
      );
    }
  }
}
function getCookie(name) {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");

  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");

    /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
}

export default App;
