import React from "react";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import baseUrl, {
  authentication,
} from "../Utils";
import { Helmet } from "react-helmet";

class TzedakaAddress extends React.Component {
  info = [];

  constructor(props) {
    super(props);
    this.state = {
      date: "",
      studentData: []
    };
    this.getStudents();
  }
  studentColumns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
      editable:false
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
      editable:false
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      editable:false
    },
    {
      dataField: "class",
      text: "Class",
      sort: true,
      editable:false
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      editable: true,
    },
  ];

  save = () => {
    let students = this.state.studentData.filter((student)=>student.amount != '');
    let notNumber = false;
    students.forEach((student) => {
      if (isNaN(student.amount)) {
        notNumber = true;
      }
    });

    if (this.state.date == "") {
      alert("Please choose a date");
    }else if(notNumber){
      alert('Amount must be a number');
    } else {
      let record = {};
      record.date = this.state.formattedDate;

      record.studentData = students;

      let formData = new FormData();

      formData.append("record", JSON.stringify(record));
      // console.log(record)

      fetch(baseUrl + "/new-tzedaka", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())

        .then((text) => {
          if (text == "successful") {
            this.reset();
          } else {
            alert(text);
          }
        });
    }
  };

  reset = () => {
    this.setState({
      date: ""
    });
    this.getStudents();
  };

  getStudents() {
    let url = `${baseUrl}/all-students`;
    fetch(url, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {

        this.setState({ studentData: res });
      });
  };


  render() {
    return (
      <div>
        <Helmet>
          <style>{`
           @media print {
                    .table-bordered td, .table-bordered th {
                    border: 1px solid black !important;
                }}         
                `}</style>
        </Helmet>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>

          <Breadcrumb.Item active>Tzedaka</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <div style={{ paddingRight: "30px", paddingBottom: "10px" }}>
            <Button
              style={{ float: "right" }}
              onClick={() => {
                this.save();
              }}
            >
              Save
            </Button>
          </div>

          <div
            className="row"
            style={{
              textAlign: "center",
              padding: "15px",
              paddingLeft: "25px",
            }}
          >
            <div className="col-sm-4" style={{ paddingTop: "10px" }}>
              <div style={{ textAlign: "center" }}>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-default">
                      Date
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <DatePicker
                    selected={this.state.date}
                    onChange={(val, a) => {
                      let d = new Date(val).toISOString().split("T")[0];
                      console.log(d);
                      this.setState({ date: val, formattedDate: d });
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </div>
          {this.state.studentData.length > 0 && (
            <div style={{ padding: "15px" }}>
              <Table
                pagination={false}
                selectable={false}
                delete={false}
                switch={false}
                editable={true}
                editFunc={(oldValue, newValue, row, column, done) => {}}
                columns={this.studentColumns}
                data={this.state.studentData}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TzedakaAddress;
