import React, {Component} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form"
// import {createChannel} from "../Utils";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Select from 'react-select';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import baseUrl, {authentication} from "../../Utils";
 
let items = []

class dialog extends Component {
    constructor(props) {
        super(props);
        this.state={
            isDialogOpen:false,
            className:"",
            points:"",
            checkbox:false,
            formattedDate: ""
        }
    }

open=()=>{
    this.setState({
        isDialogOpen:true
    })
}


    componentWillReceiveProps(nextProps) {
        switch (nextProps.isDialogOpen) {
            case  true:
            {
                this.setState({
                    isDialogOpen:true

                })
                break;
            }
            case false:
            {
                this.setState({
                    isDialogOpen:false

                })
                break;
            }

        }



    }

    uploadNewClass = ()=>{
        
        if(this.state.className!=""&&this.state.grade!="")
        {
            fetch(`${baseUrl}/class/add/${this.state.grade}/${this.state.className}${this.state.room!=""?"?room="+this.state.room:""}`,{
                headers: {
                    Authorization: authentication
                } })
            .then(
                response=>response.text())
                
                .then(text=>{
                    if(text=="successful"){
                        this.props.closeFunction()
                    } 
            })
            
            //}
           
        }

    }

    addState = (name, val) => {
        this.setState({name: val});
    }

    
    render() {
        return (
            <Modal show={this.state.isDialogOpen} onHide={this.handleSubcategoryClose}   >
                <Modal.Header closeButton={false}>
                    <Modal.Title>New {this.props.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <NotificationContainer/>
                    <div style={{textAlign:"center"}}>
                    <InputGroup className="mb-3 col-sm-12"  style={{paddingTop:"10px"}} >
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-default">{this.props.canteenJob!=true?this.props.job==true?"Weekly salary in points":(this.props.header || this.props.name+" Name"):"Wages are canteen revenue times"} </InputGroup.Text>
                        </InputGroup.Prepend>
                       { this.props.useDatePicker ? 
                        <DatePicker
                            selected={this.state.date}
                            onChange={(val, a) => {
                                let d = new Date(val).toISOString().split("T")[0];
                                this.setState({ date: val, formattedDate: d});
                            }}
                        />
                        : 
                        <FormControl
                            aria-label="Channel Name"
                            aria-describedby="inputGroup-sizing-default"
                            value={this.state.className}
                            onChange={(v)=>{
                                console.log(v.target);
                                this.setState({className:v.target.value})}}

                        /> }
                    </InputGroup>
                        {this.props.reward&&<InputGroup className="mb-3 col-sm-12"  style={{paddingTop:"10px"}} >
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">Points</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="Channel Name"
                                aria-describedby="inputGroup-sizing-default"
                                value={this.state.points}
                                onChange={(v)=>{this.setState({points:v.target.value})}}

                            />
                        </InputGroup>}
                        {this.props.name=="Subject"&&<div>
                          <span>  This subject gets points on tests </span>
                            <input type="checkbox"
                                   onChange={(v)=>{
                                       this.setState({checkbox: !this.state.checkbox})}}
                            />

                        </div>

                        }
                        {/*<InputGroup className="mb-3 col-sm-12"  style={{paddingTop:"10px"}} >
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">Room</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="Channel Name"
                                aria-describedby="inputGroup-sizing-default"
                                value={this.state.room}
                                onChange={(v)=>{this.setState({room:v.target.value})}}

                            />
                        </InputGroup>
                    <div style={{paddingLeft:"15px",paddingRight:"15px"}}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
    <Form.Label>Grade</Form.Label>
                    <Select
        //  value={grade}
          onChange={(selected)=>{this.setState({grade:selected.value})}}
        options={items}

      />
     </Form.Group>
 */}

  {/*</div>*/}
                    {/* </InputGroup> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{this.props.closeFunction();
                        this.setState({ className:"",points:""})

                    }}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={()=>{
                       
                        /*if( NotificationManager.listNotify.length==0)
                         NotificationManager.error('Error message',"",  5000, () => {
                            alert(this.state.grade);
                            console.log(this.state.grade)
                          });
                      else
                          */

                          if(this.props.reward){

                            this.props.saveFunction(this.state.className,this.state.points)
                            this.setState({ className:"",points:""})

                        }else if(this.props.name=="Subject"){

                            this.props.saveFunction(this.state.className,this.state.checkbox)
                            this.setState({ className:"",checkbox:false})

                        }else if(this.props.canteenJob==true||this.props.job==true){
                              this.setState({ className:"",points:""})
                              this.props.saveFunction(this.props.student,this.state.className)

                        }else if(this.props.useDatePicker) {
                            this.props.saveFunction(this.state.formattedDate);
                            this.setState({formattedDate: "", date: ""})
                        }
                        else {
                            this.setState({ className:"",points:""});
                            this.props.saveFunction(this.state.className)
                        }
                        }}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

dialog.propTypes = {};

export default dialog;
