/**
 * Created by Yosef Nussen
 * Date: 7/28/20
 */
 import React from "react";
 import baseUrl, {
  isTTOL,
   reportCardSchoolName,
   reportCardSchoolAddress,
   reportCardLogo,
 } from "../Utils"; 
 
 class ReportCardFront extends React.Component {
   today = new Date()
   constructor() {
     super();
     // this.getReportCard()
     // this.getComments()
   }
   
   render() {
     const classes = {
       table: {
         width: "3in",
       },
     };
     return (
         
               <div style={{ width: "100%", height: "98%" }}>
                 <div style={{ textAlign: "center", paddingTop: ".75in", marginTop:"1in"}}>
                   <img style={{ width: "2.5in" }} src={reportCardLogo} />
                 </div>
                 <div
                   style={{
                     width: "100%",
                     textAlign: "center",
                     paddingTop: this.props.isLandscape ? "150px" : ".75in",
                     fontSize: "x-large",
                     fontWeight: "bold",
                   }}
                 >
                   {reportCardSchoolName}
                 </div>
 
                 <div
                   style={{
                     width: "100%",
                     textAlign: "center",
                     height: "25px",
                     paddingTop: this.props.isLandscape ? "30px" : "0px",
                     marginTop: isTTOL ? '80px' : "0px",
                     fontWeight: isTTOL ? '900' : 'normal',
                     fontSize: isTTOL ? 25 : 'normal',
                   }}
                 >
                   {!isTTOL ? reportCardSchoolAddress : 'General Studies Report Card'}
                 </div>
                 {!isTTOL ? <><div
                   style={{
                     width: "100%",
                     textAlign: "center",
                     height: "25px",
                     paddingTop: this.props.isLandscape ? "30px" : "0px",
                     marginTop: "1in",
                     fontWeight: 'normal',
                   }}
                 >{`${this.props.firstName} ${this.props.lastName}`}</div>
                 <div
                   style={{
                     width: "100%",
                     textAlign: "center",
                     height: "25px",
                     paddingTop: this.props.isLandscape ? "30px" : "0px",
                     marginTop: "10px",
                     fontWeight: 'normal',
                   }}
                 >{`${this.props.grade} Grade`}</div></>:
<><div
                   style={{
                     width: "100%",
                     textAlign: "center",
                     height: "25px",
                     paddingTop: this.props.isLandscape ? "30px" : "0px",
                     marginTop: '15px',
                     fontWeight: 'bold',
                   }}
                 >{this.today.getMonth() > 6 ? `${this.today.getFullYear()} - ${this.today.getFullYear() + 1}` : `${this.today.getFullYear() - 1} - ${this.today.getFullYear()}`}</div><div
                   style={{
                     width: "100%",
                     textAlign: "center",
                     height: "25px",
                     paddingTop: "0px",
                     marginTop: '10px',
                     fontWeight: 'bold',
                   }}
                 >{`Grade ${this.props.grade.charAt(0)}`}</div>
                 <div
                   style={{
                     width: "100%",
                     textAlign: "center",
                     height: "25px",
                     marginTop: '10px',
                     paddingTop: this.props.isLandscape ? "30px" : "0px",
                     fontWeight: 'bold',
                   }}
                 >{`${this.props.firstName} ${this.props.lastName}`}</div>  </>               
                 }
 
                 <div></div>
               </div>
             
     );
   }
 }
 export default ReportCardFront;
 