import React from "react";

import Table from "./Table";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";

import baseUrl, { authentication } from "../Utils";
import { updateChannel } from "../Utils";

const columns = [
  {
    dataField: "student_id",
    text: "Id",
    sort: true,
  },
  { dataField: "last_name", text: "Last Name", sort: true },
  {
    dataField: "first_name",
    text: "First Name",
    sort: true,
  },
  {
    dataField: "grade",
    text: "Grade",
    sort: true,
  },
  {
    dataField: "class",
    text: "Class",
    sort: true,
  },
];

class SalaryEmployees extends React.Component {
  constructor(props) {
    super(props);

    this.state = { channels: [], newChannelOpen: false };
    // this.gradeId=this.props.history.location.state.gradeId
    // this.grade=this.props.history.location.state.grade
    this.setClasses();
  }
  setClasses() {
    fetch(`${baseUrl}/salary-workers`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ channels: res });
      });
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Salary Employees</Breadcrumb.Item>
        </Breadcrumb>
        {/* <NewChannel isDialogOpen={this.state.newChannelOpen} closeFunction={()=>{this.setState({newChannelOpen:false})}}/> */}
        <div style={{ padding: "25px" }}>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}></div>
          <Table
            delete={false}
            switch={false}
            columns={columns}
            data={this.state.channels}
            editFunc={(oldValue, newValue, row, column) => {
              updateChannel(oldValue, newValue, row, column);
            }}
            rowEvents={{
              onClick: (e, row, rowIndex) => {
                console.log(e);
                if (e.type == "click") {
                  //this.props.stateFunc(row.image)
                  this.props.history.push({
                    pathname: `/student-details/${row.student_id}`,
                    state: {
                      class: row.class,
                      classId: row.class_id,
                      gradeId: row.grade_id,
                      grade: row.grade,
                      studentId: row.student_id,
                      firstName: row.first_name,
                      lastName: row.last_name,
                    },
                  });
                }
              },
            }}
          />
        </div>
      </div>
    );
  }
}
export default SalaryEmployees;
