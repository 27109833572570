import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "./Table";

import baseUrl, {
  authentication,
  deductPoints,
  updateFirstName,
  updateLastName,
  updatePoints,
} from "../Utils";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import Barcode from "react-barcode";
import { FaCheck } from "react-icons/fa";
const options = [
  // { value: '1', label: '1' },
  { value: "-1", label: "-1" },
  { value: "-2", label: "-2" },
  { value: "-3", label: "-3" },
  { value: "-4", label: "-4" },
  { value: "-5", label: "-5" },
];

class StudentBarcodes extends React.Component {
  reset = () => {
    this.setState(
      {
        studentData: [],
        date: "",
        formattedDate: "",
      },
      this.setClass()
    );
  };
  constructor(props) {
    super(props);
    // this.getStudents()
    this.state = {
      studentData: [],
      newSubcategoryOpen: false,
      canteen: [],
      specialty: [],
      date: "",
      formattedDate: "",

      studentColumns: [
        {
          dataField: "bank_account_number",
          text: "Barcode",
          sort: true,
          row: 1,
          formatter: (cell, row, rowIndex) => {
            return (
              <div>
                <Barcode
                  value={"ac" + cell + " " + row.last_name}
                  width={1.5}
                  height={25}
                />
              </div>
            );
          },
        },
        {
          dataField: "bank_account_number",
          text: "Bank account number",
          sort: true,
          row: 1,
        },
        {
          dataField: "first_name",
          text: "First Name",
          sort: true,
          row: 1,
        },
        {
          dataField: "last_name",
          text: "Last Name",
          sort: true,
          row: 1,
        },
        {
          dataField: "points",
          text: "Points",
          sort: true,
          row: 1,
          editable: true,
        },
        {
          dataField: "class",
          text: "Class",
          sort: true,
          row: 1,
        },
      ],
    };
    this.setClass();
    /*this.getCanteen()
        this.getSpecialty()*/
  }

  setClass() {
    fetch(`${baseUrl}/bank`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
          item.points = parseInt(item.points);
          item.canteen = [];
          item.specialty = [];
        });

        this.setState({ studentData: res });
      });
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Rewards</Breadcrumb.Item>
          <Breadcrumb.Item active>Student Barcodes</Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ padding: "15px" }}>
          {this.state.studentData != undefined ? (
            <Table
              pagination={false}
              delete={false}
              editFunc={(oldValue, newValue, row, column) => {
                {
                  if (oldValue != newValue) {
                    if (column.dataField == "points") {
                      let points = newValue - oldValue;
                      deductPoints(row.id, points);
                    }
                    if (column.dataField == "first_name") {
                      updateFirstName(row.id, newValue);
                    }
                    if (column.dataField == "last_name") {
                      updateLastName(row.id, newValue);
                    }
                  }
                }
              }}
              columns={this.state.studentColumns}
              data={this.state.studentData}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default StudentBarcodes;
