import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "./Table";

import baseUrl, {
  authentication,
  deductPoints,
  updateFirstName,
  updateLastName,
  updatePoints,
} from "../Utils";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import { forEach } from "react-bootstrap/cjs/ElementChildren";
import { Type } from "react-bootstrap-table2-editor";
import Button from "react-bootstrap/Button";

class Bank extends React.Component {
  reset = () => {
    // this.setState(
    //   {
    //     studentData: [],
    //     date: "",
    //     formattedDate: "",
    //   },
    //   this.setClass()
    // );
    this.setClass()
  };
  constructor(props) {
    super(props);
    // this.getStudents()
    this.state = {
      studentData: [],
      newSubcategoryOpen: false,

      studentColumns: [
        {
          dataField: "first_name",
          text: "First Name",
          sort: true,
          row: 1,
        },
        {
          dataField: "last_name",
          text: "Last Name",
          sort: true,
          row: 1,
        },
        {
          dataField: "bank_account_number",
          text: "Bank account number",
          sort: true,
          editable: false,
        },
        {
          dataField: "points",
          text: "Points",
          sort: true,
          row: 1,
          editable: true,
        },
        {
          dataField: "marks",
          text: "Marks",
          sort: true,
          editable: false,
        } /*,{
        dataField: 'tests',
        text: 'Missing Tests',
        sort: true,
        editable: false
    },{
        dataField: 'retake',
        text: 'Missing Retakes',
        sort: true,
        editable: false
    }
*/,
        {
          dataField: "grade",
          text: "Grade",
          sort: true,
          editable: false,
        },
        {
          dataField: "class",
          text: "Class",
          sort: true,
          editable: false,
        },
        {
          dataField: "note",
          text: "Notes",
          headerStyle: (column, colIndex) => {
            return { width: "250px", textAlign: "center" };
          },
          editor: {
            type: Type.TEXTAREA,
          },
        },
        {
          dataField: "deposit",
          text: "deposit",
          sort: true,
          editable: true,
          headerStyle: (column, colIndex) => {
            return { width: "150px", textAlign: "center" };
          },
        },
      ],
    };
    this.setClass();
  }
  submiting = false;
  setClass() {
    fetch(`${baseUrl}/bank`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
          item.points = parseInt(item.points);
          item.deposit = "";
          item.note = "";
        });

        this.setState({ studentData: res }, () => {
          this.submiting = false;
        });
      });
  }
  save() {
    if (!this.submiting) {
      this.submiting = true;
      let record = {};
      record.deposits = [];

      this.state.studentData.map((d) => {
        if (d.deposit && d.deposit != 0) {
          let r = {};
          r.id = d.id;
          r.deposit = d.deposit;
          if (d.note) {
            r.note = d.note;
          }
          record.deposits.push(r);
        }
      });

      // record.studentData=this.state.studentData
      console.log(record);
      let formData = new FormData();
      formData.append("action", "deposit");
      formData.append("deposits", JSON.stringify(record));
      // console.log(record)

      fetch(baseUrl + "/deposit", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())

        .then((text) => {
          if (text == "successful") {
            this.reset();
          } else {
            alert(text);
          }
          // this.setClass();
        });
    }
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Rewards</Breadcrumb.Item>
          <Breadcrumb.Item active>Deposit</Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ padding: "15px" }}>
          <div
            className="row"
            style={{ textAlign: "center", paddingBottom: "15px" }}
          >
            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingTop: "10px" }}
            ></div>

            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingRight: "30px", paddingBottom: "10px" }}
            >
              <div style={{ paddingRight: "30px", paddingBottom: "10px" }}>
                <Button
                  style={{ float: "right" }}
                  onClick={() => {
                    this.save();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>

          {this.state.studentData != undefined ? (
            <Table
              paginationDownward={true}
              selectable={false}
              delete={false}
              switch={false}
              editable={true}
              editFunc={(oldValue, newValue, row, column) => {
                {
                  if (oldValue != newValue) {
                    if (column.dataField == "points") {
                      let points = newValue - oldValue;
                      deductPoints(row.id, points);
                    }
                    if (column.dataField == "first_name") {
                      updateFirstName(row.id, newValue);
                    }
                    if (column.dataField == "last_name") {
                      updateLastName(row.id, newValue);
                    }
                    else{
                      return newValue
                    }
                  }
                }
              }}
              columns={this.state.studentColumns}
              data={this.state.studentData}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default Bank;
