import React from "react";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import { Type } from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Form } from "bootstrap-4-react";
import baseUrl, {
  authentication,
  getClasses,
  getGrades,
  getSubjects,
  getTeachers,
  setNewTestClass,
  showCommentsOnReportCard
} from "../Utils";
import { Helmet } from "react-helmet";
import ReactDataGrid from "react-data-grid";
import ReportCard from "./ReportCard";
import ReactToPrint from "react-to-print";
import ReportCardLandscape from "./ReportCardLandscape";

class Reportcards extends React.Component {
  info = [];

  constructor(props) {
    super(props);
    /*this.state={
            classId:0,
            studentData:[],
            defaultGradeSelectVal:"default",
            defaultClassSelectVal:"default",
            defaultTeacherSelectVal:"default",
            selectVal:"d",
            grades:[],
            grade:"",
            classes:[],
            reportcardData:[]

        }
        getGrades(this)*/
    // this.getClasses()
  }

  /*

    getReportCardInfo(classId){


        fetch(`${baseUrl}/class-report-card/${classId}`,{
            headers: {
                Authorization: authentication
            } }).then(response => {
            return response.json();
        }).then(res => {
            this.setRows(res)
           // this.setState({reportcardData: res},this.setRows())

        })


    }
    setRows(res){
        res.map((student)=>{
            let rows=[];
            let columns=[];
                let behaviorRows=[];
                let behaviorColumns=[];

            student.subjects.map((subject,x)=>{


                let  row=[subject.name]
                subject.terms.map((term,index)=>{
                    if(x==0){
                        columns.push(term.term)
                    }
                    row.push(term.mark)
                })
                rows.push(row);


            })
            student.reportcardRows=rows;
            student.reportcardColumns=columns

                student.behavior[0].map((behave,x)=>{
                    behaviorColumns.push(behave.term)
                })
                student.behavior.map((behave,x)=>{
                    let row=[];
                    behave.map((t,i)=>{

                        if(i==0){
                            row.push(t.name)
                        }
                        row.push(t.mark)
                    })
                    behaviorRows.push(row);

                })
            console.log(behaviorRows)

                student.behaviorColumns=behaviorColumns
                student.behaviorRows=behaviorRows


        }

        )

        this.setState({reportcardData:res})

    }
    setClass(){
        let formData=new FormData()
        formData.append("action","get class")
        formData.append('id', this.state.classId)
        var url = "http://ohrhatorah.appsenses.com/students.php"

        fetch(url, {
                method: 'POST',
                body: formData

            }
        ).then(response=>{ return response.json();}).then(res=>{
            res.map((item)=>{
                item.id=parseInt(item.id)
                item.mark=""

                this.info.push(item)


            })
            this.setState({studentData:res})

        })
    }


    absentFormatter=(cell,row,rowIndex)=>{
        let data=this.state.studentData
        console.log(row)
        data.map((d)=>{if(d.id==row.id){d.absent=!d.absent}})

        this.setState({studentData:data})
    }




reset=()=>{this.setState(
    {
        classId:0,date:"",topic:"",term:"",gradeName:"",
        studentData:[],
        defaultGradeSelectVal:"default",
        defaultClassSelectVal:"default",
        defaultTeacherSelectVal:"default",aSet:false,bSet:false,cSet:false,

        teacherId:"",
        selectVal:"d",

        grade:"",

        isDialogOpen: false,
        isSubcategoryDialogOpen:false,reload:false,
        subjectId:"",defaultSubjectSelectVal:"default",defaultTermSelectVal:"default",testType:0

    }
)}*/

  /*
    ComponentToPrint = React.forwardRef((props, ref) => {
        <div>this.state.reportcardData.length>0&&this.state.reportcardData.map(student=><ReportCard ref={el => (this.reportCardRef = el)} studentId={student.id}
                        firstName={student.first_name} lastName={student.last_name} grade={this.state.gradeName}
                        studentData={this.state.reportCardStudentData}
                        term1Comment={student.term_1_comment?student.term_1_comment:""} term2Comment={student.term_2_comment?student.term_2_comment:""}
                        term3Comment={student.term_3_comment?student.term_3_comment:""} rows={student.reportcardRows}
                        columns={student.reportcardColumns}
                        behaviorRows={student.behaviorRows} behaviorColumns={student.behaviorColumns}/>)</div>

    })*/

  render() {
    return (
      <div>
        {this.props.reportcardData.length > 0 &&
          this.props.reportcardData.map((student) => ( 
            showCommentsOnReportCard ? 
            <ReportCard
              ref={(el) => (this.reportCardRef = el)}
              studentId={student.id}
              firstName={student.first_name}
              lastName={student.last_name}
              grade={this.props.gradeName}
              studentData={this.props.reportCardStudentData}
              showComments={showCommentsOnReportCard}
              term1Comment={
                student.term_1_comment ? student.term_1_comment : ""
              }
              term2Comment={
                student.term_2_comment ? student.term_2_comment : ""
              }
              term3Comment={
                student.term_3_comment ? student.term_3_comment : ""
              }
              rows={student.reportcardRows}
              columns={student.reportcardColumns}
              behaviorRows={student.behaviorRows}
              behaviorColumns={student.behaviorColumns}
              honorRole={[student.honorRole]}
            /> : 
            <ReportCardLandscape
            ref={(el) => (this.reportCardRef = el)}
            studentId={student.id}
            firstName={student.first_name}
            lastName={student.last_name}
            grade={this.props.gradeName}
            studentData={this.props.reportCardStudentData}
            rows={student.reportcardRows}
            columns={student.reportcardColumns}
            behaviorRows={student.behaviorRows}
            behaviorColumns={student.behaviorColumns}
            honorRole={[student.honorRole]}
            />
          ))}
        {/* <Helmet>
                <style>{`
           @media print {
                    .table-bordered td, .table-bordered th {
                    border: 1px solid black !important;
                }}         
                `}</style>
            </Helmet>
            <Breadcrumb>
                <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="tests">Tests</Breadcrumb.Item>
                <Breadcrumb.Item active>New Test</Breadcrumb.Item>

            </Breadcrumb>
            <div >


                <div className="row" style={{textAlign:"center",padding:"15px",paddingLeft:"25px"}}>

                    <div className="col-sm-12" style={{paddingTop:"10px"}}>
                        <Form.CustomSelect lg mb="3" value={this.state.defaultGradeSelectVal} onChange={(v)=> {
                            if (v.target.value != "default") {

                                let gradeName=""
                                this.state.grades.map(grade=>{if(grade.id==v.target.value){gradeName= grade.name}})
                                this.setState({grade: v.target.value,gradeName:gradeName, defaultGradeSelectVal: v.target.value,defaultClassSelectVal:"default"})
                                getClasses(v.target.value,this);
                                console.log(this.state.grade)
                            }
                        }
                        }>
                            {this.state.grades.map((x,y) => <option value={x.id} key={y}>{x.name}</option>)}
                            { this.state.defaultGradeSelectVal=="default"  && <option value="default" >Choose Grade</option>}

                        </Form.CustomSelect>

                    </div>

                </div>

                {this.state.grade != "" &&
                <div className="row" style={{textAlign: "center", padding: "15px", paddingLeft: "25px"}}>


                    <div className="col-sm-3" style={{paddingTop: "10px"}}>
                        {this.state.grade != "" &&
                        <Form.CustomSelect lg mb="3" value={this.state.defaultClassSelectVal} onChange={(v) => {
                            if (v.target.value != "default") {
                                this.setState({
                                    classId: v.target.value,
                                    defaultClassSelectVal: v.target.value
                                }, this.getReportCardInfo(v.target.value, this));
                            }
                        }}>
                            {this.state.classes.map((x, y) => <option value={x.id} key={y}>{x.class}</option>)}
                            {this.state.defaultClassSelectVal == "default" &&
                            <option value="default">Choose Class</option>}

                        </Form.CustomSelect>
                        }

                    </div>


                </div>}*/}
        {/*term1Comment={student.comments&&student.comments}*/}
        {/* <ReactToPrint

                    trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.
                        return <a href="#">Print Report Card</a>;
                    }}
                    content={() => this.reportCardDiv}
                />

                 <this.ComponentToPrint ref={this.reportCardDiv}/>*/}

        {/*</div>*/}
        {/*{this.state.studentData.length>0&&

                <div style={{padding:"15px"}}><Table pagination={false} selectable={false} delete={false} switch={false} editable={true}
                                                     editVerify={true}
                                                     editFuncVerify={(oldValue, newValue, row, column,done)=>{
                                                         if(column.text=="Notes"){
                                                             this.info.map((item,index)=>{
                                                                 if(item.id==row.id){
                                                                     item.note=newValue
                                                                 }
                                                             })}if(column.dataField=="mark"){
                                                             if(isNaN(newValue)){
                                                                 newValue=""
                                                                 alert("numbers only")
                                                                 done(false);
                                                                 return false

                                                             }
                                                         }


                                                     }}
                                                     columns={this.studentColumns} data={this.state.studentData}

                />

                </div>



                }*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default Reportcards;
