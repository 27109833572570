import React from "react";
import Table from "./Table";
import Button from "react-bootstrap/Button";
import { Type } from "react-bootstrap-table2-editor";
import {
  authentication,
} from "../Utils";
import baseUrl from "../Utils";

const options = [
  {
    value: "Contact Parents",
    label: "Contact Parents",
  },
  {
    value: "Do Not Contact Parents",
    label: "Do Not Contact Parents",
  },
  {
    value: "Parents Contacted",
    label: "Parents Contacted",
  },
  {
    value: "Detention",
    label: "Detention",
  },
  {
    value: "Test Taken",
    label: "Test Taken",
  },
];

class DelinquentTest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      studentData: [],
      studentColumns: [
        {
          dataField: "status",
          text: "Status",
          sort: true,
          editable: true,
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              return options;
            },
          },
        },
        {
          dataField: "first_name",
          text: "First Name",
          sort: true,
          editable: false,
        },
        {
          dataField: "last_name",
          text: "Last Name",
          sort: true,
          editable: false,
        },
        {
          dataField: "class",
          text: "Class",
          sort: true,
          editable: false,
        },
        {
          dataField: "teacher",
          text: "Teacher",
          sort: true,
          editable: false,
        },
        {
          dataField: "days_delinquent",
          text: "Days Delinquent",
          sort: true,
          editable: false,
        },
        {
          dataField: "missing_test",
          text: "Missing Tests",
          sort: true,
          editable: false,
        },
        {
          dataField: "missing_retake",
          text: "Missing Retakes",
          sort: true,
          editable: false,
        },        
        {
          dataField: "notes",
          text: "Notes",
          sort: true,
          editable: true,
        },
        {
          dataField: "test_mark",
          text: "Enter Test Grade",
          sort: true,
          editable: true,
        },
        {
          dataField: "excuseTest",
          text: "Excuse Test",
          sort: true,
          editable: false,
          formatter: (cell, row, rowIndex) => {
            if(row.missing_test){
              return(
                <div>
                <Button onClick={()=>{this.excuseTest(row.test_id,row.student_id)}}>Excuse</Button>
              </div>
              )
            }
            return '';
          },
        },
      ],
    };
    this.getMissingTests();
  }
  updateDetention(detention_id, dataField, newValue){
    let url = baseUrl + `/update-detention?detention_id=${detention_id}&column=${dataField}&value=${newValue}`;
    fetch(url, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.getMissingTests()
      });
  }
  createDetention(row){
    let type = row.missing_test? "test": "retake"
    let url = baseUrl + `/create-detention?student_id=${row.student_id}&test_id=${row.test_id}&status=${row.status}&note=${row.notes}&type=${type}`
    fetch(url, {
      headers: {
        Authorization: authentication,
      },
    })
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      this.getMissingTests()
    });
  }
  getMissingTests() {
    fetch(`${baseUrl}/all-detentions`, {
      headers: {
        Authorization: authentication,
      },
    })
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      this.setState({studentData:res})
    });
  }
  excuseTest(test_id,student_id) {
    fetch(`${baseUrl}/excuse/${test_id}/${student_id}`,{
      headers:{
        Authorization: authentication,
      },
    })
    .then(()=>{
      this.getMissingTests();
    })
  }
  enterTestGrade(test_id,student_id,mark){
    fetch(`${baseUrl}/update-test/-1/${mark}/${test_id}/${student_id}`,{
      headers:{
        Authorization: authentication,
      },
  })
    .then(()=>{
      this.getMissingTests();
    })
  }
  enterRetakeGrade(test_id,student_id,mark) {
    fetch(`${baseUrl}/submit-retake/${student_id}/${test_id}/${mark}`,{
        headers:{
          Authorization: authentication,
        },
    })
      .then(()=>{
        this.getMissingTests();
      })
  }

  render() {
    return (
      <div>
        <Table
          pagination = {false}
          editFunc={(oldValue, newValue, row, column) => {
            if(oldValue == newValue ){
              return;
            }
            if(column.dataField === 'test_mark'){
              if(isNaN(newValue) || (newValue < 0 || newValue > 200) ){
                return this.getMissingTests();
              }
              if(row.missing_test){
                return this.enterTestGrade(row.test_id,row.student_id,newValue);
              }else{
                return this.enterRetakeGrade(row.test_id,row.student_id,newValue);
              }
            }
            if (!row.detention_id) {
              if(column.dataField !=="notes"){
                row.notes = '';
              }
              row[column.dataField] = newValue;
              return this.createDetention(row);
            }else{
              return this.updateDetention(row.detention_id, column.dataField, newValue);
            }
          }}
          columns={this.state.studentColumns}
          data={this.state.studentData}
        />
      </div>
    );
  }
}
export default DelinquentTest;
