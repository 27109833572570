/**
 * Created by Yosef Nussen
 * Date: 7/28/20
 */
 import React from "react";
import NewReportCardComments from "./NewReportCardComments";
 
 class ReportCardComments extends React.Component {
   constructor() {
     super();
     // this.getReportCard()
     // this.getComments()
   }
   
   render() {
     const classes = {
       table: {
         width: "3in",
       },
     };
     return (
        <div
        style={{ width: "100%", height: "100%", textAlign: "center" }}
      >
        <div
          style={{ border: "3px solid", height: "2in", width: "95%" }}
        >
          <div style={{ border: "1px solid", width: "100%" }}>
            <h3
              style={{
                margin: 0,
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              Comment:
             </h3>
          </div>
          {this.props.term1Comment != "" && (
            <div style={{ textAlign: "left", padding: "5px" }}>
              {this.props.term1Comment.comment}
            </div>
          )}
        </div>
        <div
          style={{
            border: "3px solid",
            height: "2in",
            width: "95%",
            marginTop: ".75in",
          }}
        >
          <div style={{ border: "1px solid", width: "100%" }}>
            <h3
              style={{
                margin: 0,
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              Comment:
            </h3>
          </div>
          {this.props.term2Comment != "" && (
            <div style={{ textAlign: "left", padding: "5px" }}>
              {this.props.term2Comment.comment}
            </div>
          )}
        </div>
        <div
          style={{
            border: "3px solid",
            height: "2in",
            width: "95%",
            marginTop: ".75in",
          }}
        >
          <div style={{ border: "1px solid", width: "100%" }}>
            <h3
              style={{
                margin: 0,
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              Comment:
            </h3>
          </div>
          {this.props.term3Comment != "" && (
            <div style={{ textAlign: "left", padding: "5px" }}>
              {this.props.term3Comment.comment}
            </div>
          )}
        </div> 

        <div></div>
      </div>
     );
   }
 }
 export default ReportCardComments;
 