import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import baseUrl, { authentication } from "../Utils";
import Dialog from "./dialogs/dialog";
import Table from "./Table";
import Confirm from "./dialogs/Confirm";

export default function AdvancedMarksResetDates() {
  const [submiting, setSubmiting] = useState(false);
  const [marksResetDatesDisabled, setMarksResetDatesDisabled] = useState([]);
  const [marksResetDates, setMarksResetDates] = useState([]);
  const [marksResetDatesOpen, setMarksResetDatesOpen] = useState(false);
  const [marksResetNowOpen, setMarksResetNowOpen] = useState(false);


const marksResetDatesColumns = [
  {
    dataField: "date",
    text: "Date when marks reset to 0",
    sort: true,
  },
];

let marksResetDatesTableRef;

  useEffect(() => {
    getMarksResetDates();
  }, []);
  
  const getMarksResetDates = () => {
    fetch(`${baseUrl}/get-marks-reset-dates`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        const disabledRows = [];
        res.forEach((val, ind) => {
          if (!val.is_date_future) {
            disabledRows.push(ind+1);
          }
        })
        setMarksResetDates(res);
        setMarksResetDatesDisabled(disabledRows);
      });
  }

  const saveNewMarksResetDates = (date) => {
    fetch(`${baseUrl}/new-marks-reset-dates/${date}`, {
      headers: {
        Authorization: authentication,
      },
    }).then(() => {
      setMarksResetDatesOpen(false );
      getMarksResetDates();
    });
  };

  const resetMarksNow = () => {
    fetch(`${baseUrl}/reset-marks-now`, {
      headers: {
        Authorization: authentication,
      },
    }).then(() => {
      setMarksResetNowOpen(false );
      getMarksResetDates();
    });
  };

  const deleteDate = (id, callback) => {
    fetch(`${baseUrl}/delete-marks-reset-dates/${id}`, {
      method: "POST",
      headers: {
        Authorization: authentication,
      },
    })
      .then(callback)
      .then(getMarksResetDates)
      .catch((err) => {
        alert(err);
        console.error(err);
      });
  };

  const deleteMarksResetDate = (ids) => {
    if (ids.length > 0) {
      let formData = new FormData();
      formData.append("ids", ids);
      
      fetch(`${baseUrl}/delete-marks-reset-dates`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())
        .then((text) => {
          if (text == "successful") {
            getMarksResetDates();
          }
        });
    }
  }

  return (
    <>
    <Dialog
          name="Marks Reset Dates"
          isDialogOpen={marksResetDatesOpen}
          saveFunction={ saveNewMarksResetDates }
          header="Date marks should reset to 0"
          closeFunction={() => {
            setMarksResetDatesOpen(false);
          }}
          useDatePicker={true}
        />

    <Confirm
          name="Confirm Reset"
          isOpen={marksResetNowOpen}
          saveFunction={ resetMarksNow }
          closeFunction={() => {
            setMarksResetNowOpen(false);
          }}
        >
          Are you sure you want to reset all marks? This action cannot be reversed.
        </Confirm>
        
      <h2 style={{ textAlign: "center", marginBottom: 20 }}>
        Advanced Mark Reset Date Settings
      </h2>
      <h6 style={{ textAlign: "center", marginBottom: 50 }}>
      On the following dates all marks will be reset to 0
      </h6>
      <div
        style={{
          width: "80%",
          margin: "auto",
        }}
      > 
          <div>
            <Button onClick={()=>{ setMarksResetNowOpen(true)}}>Reset Now</Button>
            <Table
              ref={(n) => (marksResetDatesTableRef = n)}
              addFunction={() => {
                setMarksResetDatesOpen(true);
              }}
              add={true}
              pagination={false}
              search={false}
              delete={true}
              selectRow = {{
                mode: 'checkbox',
                clickToSelect: true,
                nonSelectable: marksResetDatesDisabled, 
                nonSelectableStyle: { backgroundColor: 'light-gray', color: 'gray' }
              }}
              editable={true}
              deleteClick={() => {
                deleteMarksResetDate(marksResetDatesTableRef.state.selected);
              }}
              switch={false}
              columns={marksResetDatesColumns}
              data={marksResetDates}
            />
          </div>
      </div>
    </>
  );
}
