import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import { authentication, createChannel,newPhoneNumb } from "../Utils";
import Select from "react-select";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import baseUrl from "../Utils";
import CSVReader from "react-csv-reader";

let items = [];

class newStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      firstName: "",
      lastName: "",
      grade: this.props.gradeId,
      classId: this.props.classId,
    };
  }

  open = () => {
    this.setState({
      isDialogOpen: true,
    });
  };
  students = "";

  componentWillReceiveProps(nextProps) {
    switch (nextProps.isDialogOpen) {
      case true: {
        this.setState({
          isDialogOpen: true,
        });
        break;
      }
      case false: {
        this.setState({
          isDialogOpen: false,
        });
        break;
      }
    }
    //  items=nextProps.possibleGrades

    // for (const [index, value] of nextProps.possibleGrades.entries()) {
    // items.push(<option key={value.id} >{value.name}</option>)
    // }
  }

  uploadNewClass = () => {
    if (this.students == "") {
      if (NotificationManager.listNotify.length == 0)
        NotificationManager.error(
          "Please choose a valid csv file with first name,last name format.",
          "",
          5000,
          () => {}
        );
    } else {
      if(newPhoneNumb){
        this.students = { students: this.students };
        let formData = new FormData();
        formData.append("students", JSON.stringify(this.students));
        fetch(
          `${baseUrl}/students/add-bulk-new/${this.state.grade}/${this.state.classId}`,
          {       
            method: "POST",
            body: formData,
            headers: {
              Authorization: authentication,
            },
          }
        )
          .then((response) => response.text())

          .then((text) => {
            this.students = "";

            if (text == "successful") {
              this.props.closeFunction();
            }else{
              console.log(text)
              //alert(text)
            }
          });
      }else{       
      this.students = { students: this.students };
      this.students = JSON.stringify(this.students);
      fetch(
        `${baseUrl}/students/add-bulk/${this.state.grade}/${this.state.classId}/${this.students}`,
        {
          headers: {
            Authorization: authentication,
          },
        }
      )
        .then((response) => response.text())

        .then((text) => {
          this.students = "";

          if (text == "successful") {
            this.props.closeFunction();
          }
        });
      }

    }
  };

  render() {
    return (
      <Modal
        show={this.state.isDialogOpen}
        onHide={this.handleSubcategoryClose}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Upload New Students from csv file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NotificationContainer />
          <div style={{ textAlign: "center" }}>
            <CSVReader
              onFileLoaded={(data, fileInfo) => {
                this.students = data;
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              this.props.closeFunction();
              this.setState({ channelName: "" });
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              this.uploadNewClass();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

newStudent.propTypes = {};

export default newStudent;
