import React from "react";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import { Type } from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Form } from "bootstrap-4-react";
import baseUrl, {
  authentication,
  getClasses,
  getGrades,
  getSubjects,
  getTeachers,
  setNewTestClass,
} from "../Utils";
import { Helmet } from "react-helmet";
import ReactDataGrid from "react-data-grid";
import ReportCard from "./ReportCard";
import ReactToPrint from "react-to-print";
import Reportcards from "./Reportcards";

class ClassReportcards extends React.Component {
  info = [];

  constructor(props) {
    super(props);
    this.state = {
      classId: 0,
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",
      defaultTeacherSelectVal: "default",
      selectVal: "d",
      grades: [],
      grade: "",
      classes: [],
      reportcardData: [],
      years: [],
      year: "",
    };
    getGrades(this);
    this.getYears();
    // this.getClasses()
  }
  studentColumns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "note",
      text: "Notes",
      editor: {
        type: Type.TEXTAREA,
      },
    },
    {
      dataField: "mark",
      text: "Mark",
      sort: true,
      editable: true,
    },
  ];

  studentColumns2 = [
    {
      key: "first_name",
      name: "First Name",
      // sort: true
    },
    {
      key: "last_name",
      name: "Last Name",
      // sort: true
    },
    {
      key: "note",
      name: "Notes",
      // editor: {
      //     type: Type.TEXTAREA
      // }
    },

    {
      key: "mark",
      name: "Mark",
      // sort: true,
      // editable:true
    },
  ];

  getReportCardInfo(classId, year = "") {
    fetch(`${baseUrl}/class-report-card/${classId}?year=${year}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setRows(res);
        // this.setState({reportcardData: res},this.setRows())
      });
  }
  getYears() {
    fetch(`${baseUrl}/years`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({ years: res });
      });
  }
  setRows(res) {
    res.map((student) => {
      let rows = [];
      let columns = [];
      let behaviorRows = [];
      let behaviorColumns = [];

      student.subjects.map((subject, x) => {
        let row = [subject.name];
        subject.terms.map((term, index) => {
          if (x == 0) {
            columns.push(term.term);
          }
          row.push(term.mark);
        });
        rows.push(row);
      });
      student.reportcardRows = rows;
      student.reportcardColumns = columns;

      student.behavior[0].map((behave, x) => {
        behaviorColumns.push(behave.term);
      });
      student.behavior.map((behave, x) => {
        let row = [];
        behave.map((t, i) => {
          if (i == 0) {
            row.push(t.name);
          }
          row.push(t.mark);
        });
        behaviorRows.push(row);
      });

      student.behaviorColumns = behaviorColumns;
      student.behaviorRows = behaviorRows;
      student.honorRole.unshift('Honor Roll')
      //student.honorRole = student.honorRole.unshift("Honor Role");
    });

    this.setState({ reportcardData: res });
  }

  absentFormatter = (cell, row, rowIndex) => {
    let data = this.state.studentData;
    data.map((d) => {
      if (d.id == row.id) {
        d.absent = !d.absent;
      }
    });

    this.setState({ studentData: data });
  };

  reset = () => {
    this.setState({
      studentData: [],
      reportcardData: [],
    });
  };

  render() {
    return (
      <div>
        <Helmet>
          <style>{`
           @media print {
                    .table-bordered td, .table-bordered th {
                    border: 1px solid black !important;
                }} 
                .MuiTableCell-root {
                border-bottom: 2px solid rgba(124, 124, 124, 1) !important;
                }       
                `}</style>
        </Helmet>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>

          <Breadcrumb.Item active>Class Reportcards</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <div
            style={{
              textAlign: "left",
              display: "inline-block",
              float: "left",
              margin: "25px",
            }}
          >
            <Form.CustomSelect
              lg
              mb="3"
              value={this.state.year == "" ? "current" : this.state.year}
              onChange={(v) => {
                let y = "";
                if (v.target.value != "current") {
                  y = v.target.value;
                }

                this.setState(
                  { year: y },
                  this.getReportCardInfo(this.state.classId, y)
                );
              }}
            >
              {this.state.years.map((x, y) => (
                <option value={x} key={x}>
                  {x}
                </option>
              ))}
              this.state.year=="" &&{" "}
              <option value="current">Current Year</option>
            </Form.CustomSelect>
          </div>

          <div
            className="row"
            style={{
              textAlign: "center",
              padding: "15px",
              paddingLeft: "25px",
            }}
          >
            <div className="col-sm-12" style={{ paddingTop: "10px" }}>
              <Form.CustomSelect
                lg
                mb="3"
                value={this.state.defaultGradeSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    let gradeName = "";
                    this.state.grades.map((grade) => {
                      if (grade.id == v.target.value) {
                        gradeName = grade.name;
                      }
                    });
                    this.setState({
                      grade: v.target.value,
                      gradeName: gradeName,
                      defaultGradeSelectVal: v.target.value,
                      defaultClassSelectVal: "default",
                    });
                    getClasses(v.target.value, this);
                    console.log(this.state.grade);
                  }
                }}
              >
                {this.state.grades.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.name}
                  </option>
                ))}
                {this.state.defaultGradeSelectVal == "default" && (
                  <option value="default">Choose Grade</option>
                )}
              </Form.CustomSelect>
            </div>
          </div>

          {this.state.grade != "" && (
            <div
              className="row"
              style={{
                textAlign: "center",
                padding: "15px",
                paddingLeft: "25px",
              }}
            >
              <div className="col-sm-3" style={{ paddingTop: "10px" }}>
                {this.state.grade != "" && (
                  <Form.CustomSelect
                    lg
                    mb="3"
                    value={this.state.defaultClassSelectVal}
                    onChange={(v) => {
                      if (v.target.value != "default") {
                        this.setState(
                          {
                            classId: v.target.value,
                            defaultClassSelectVal: v.target.value,
                            studentData: [],
                            reportcardData: [],
                          },
                          this.getReportCardInfo(
                            v.target.value,
                            this.state.year
                          )
                        );
                      }
                    }}
                  >
                    {this.state.classes.map((x, y) => (
                      <option value={x.id} key={y}>
                        {x.class}
                      </option>
                    ))}
                    {this.state.defaultClassSelectVal == "default" && (
                      <option value="default">Choose Class</option>
                    )}
                  </Form.CustomSelect>
                )}
              </div>
            </div>
          )}
          {/*term1Comment={student.comments&&student.comments}*/}

          {/*<this.ComponentToPrint ref={this.reportCardDiv}/>*/}

          {/*</div>*/}
          <ReactToPrint
            trigger={() => {
              // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
              // to the root node of the returned component as it will be overwritten.
              return <a href="#">Print Report Card</a>;
            }}
            content={() => this.reportCards}
          />
          {this.state.reportcardData.length > 0 && (
            <Reportcards
              ref={(el) => (this.reportCards = el)}
              reportcardData={this.state.reportcardData}
              gradeName={this.state.gradeName}
              studentData={this.state.reportCardStudentData}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ClassReportcards;
