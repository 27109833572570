import React from "react";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import { Type } from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Form } from "bootstrap-4-react";
import baseUrl, {
  authentication,
  getClasses,
  getGrades,
  getSubjects,
  getTeachers,
  setNewTestClass,
} from "../Utils";
import { Helmet } from "react-helmet";
import { Input } from "@material-ui/core";
import ReactDataGrid from "react-data-grid";

class NewTest extends React.Component {
  info = [];

  constructor(props) {
    super(props);
    this.state = {
      classId: 0,
      date: "",
      topic: "",
      term: "",
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",
      defaultTeacherSelectVal: "default",
      teachers: [],
      teacherId: "",
      selectVal: "d",
      grades: [],
      grade: "",
      classes: [],
      isDialogOpen: false,
      isSubcategoryDialogOpen: false,
      reload: false,
      subjects: [],
      subjectId: "",
      defaultSubjectSelectVal: "default",
      defaultTermSelectVal: "default",
      testType: -1,
      aSet: false,
      bSet: false,
      cSet: false,
      dSet: false,
    };
    getGrades(this);
    getTeachers(this);
    getSubjects(this);
    this.getClasses();
    //this.getStudents()
  }
  studentColumns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
    } /*,{
        dataField:"point",
        formatter:(cell,row,rowIndex)=>{return<div><input value={cell}  type="number" onChange={(e)=>{this.info.map((item)=>{if(item.id==row.id){item.point=e.target.value}});this.setState({studentData:this.info})}}/></div>},
        text: 'Points',
        sort: true,
        editable:false

    }*/,
    {
      dataField: "note",
      text: "Notes",
      editor: {
        type: Type.TEXTAREA,
      },
    },
    {
      dataField: "mark",
      text: "Mark",
      sort: true,
      editable: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px", textAlign: "center" };
      },
    },
    {
      dataField: "excused",
      text: "Excuse",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "150px", textAlign: "center" };
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            <input
              defaultChecked={cell}
              type="checkbox"
              onChange={(e) => {
                this.info.map((item) => {
                    if (item.id == row.id) {
                      item.excused = e.target.checked;
                    }
                  });
                  this.setState({ studentData: this.info });
                }}
              />
            </div>
          );
      }, 
    },
  ];

  studentColumns2 = [
    {
      key: "first_name",
      name: "First Name",
      // sort: true
    },
    {
      key: "last_name",
      name: "Last Name",
      // sort: true
    } /*,{
        dataField:"point",
        formatter:(cell,row,rowIndex)=>{return<div><input value={cell}  type="number" onChange={(e)=>{this.info.map((item)=>{if(item.id==row.id){item.point=e.target.value}});this.setState({studentData:this.info})}}/></div>},
        text: 'Points',
        sort: true,
        editable:false

    }*/,
    {
      key: "note",
      name: "Notes",
      // editor: {
      //     type: Type.TEXTAREA
      // }
    },

    {
      key: "mark",
      name: "Mark",
    },
  ];

  getClasses() {
    var url2 = new URL("http://ohrhatorah.appsenses.com/getClasses.php");
    fetch(url2)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ classes: res });
      });
  }
  setClass() {
    let formData = new FormData();
    formData.append("action", "get class");
    formData.append("id", this.state.classId);
    var url = "http://ohrhatorah.appsenses.com/students.php";

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
          item.mark = "";

          this.info.push(item);
        });
        this.setState({ studentData: res });
      });
  }

  absentFormatter = (cell, row, rowIndex) => {
    let data = this.state.studentData;
    console.log(row);
    data.map((d) => {
      if (d.id == row.id) {
        d.absent = !d.absent;
      }
    });

    this.setState({ studentData: data });
  };
  /*{
        dataField: 'id',
        text: 'Id',
        sort: true
    },*/

  save = () => {
    let hasMarks = false;
    let hasNonNumber = false;
    this.state.studentData.map((t) => {
      if (t.mark != "") {
        hasMarks = true;
      }
      if (isNaN(t.mark)) {
        hasNonNumber = true;
      }
    });

    if (this.state.date == "") {
      alert("Please choose a date");
    } else if (this.state.teacherId == "") {
      alert("Please choose a teacher");
    } else if (this.state.classId == 0) {
      alert("Please choose a class");
    } else if (this.state.topic == "") {
      alert("Please fill in the topic field");
    } else if (this.state.term == "") {
      alert("Please choose a term");
    } else if (this.state.subjectId == "") {
      alert("Please choose a subject");
    } else if (this.state.testType == -1) {
      alert("Please choose a test type");
    } else if (!hasMarks) {
      alert("Please fill in marks");
    } else if (hasNonNumber) {
      alert("Number only please");
    } else {
      let record = {};
      record.class = this.state.classId;
      record.date = this.state.formattedDate;
      record.topic = this.state.topic;
      record.studentData = this.state.studentData;
      record.term = this.state.term;
      record.subject = this.state.subjectId;
      record.teacher = this.state.teacherId;
      record.testType = this.state.testType;
      record.excused = this.state.excused;

      let formData = new FormData();
      formData.append("action", "save test");
      formData.append("record", JSON.stringify(record));
      // console.log(record)

      fetch(baseUrl + "/test", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())

        .then((text) => {
          if (text == "successful") {
            this.reset();
          } else {
            alert(text);
          }

          //this.info=[];
          // this.setState({selectVal:"d",classId:"",topic:"",studentData:[],date:""})
        });
    }
  };
  reset = () => {
    this.setState({
      classId: 0,
      date: "",
      topic: "",
      term: "",
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",
      defaultTeacherSelectVal: "default",
      aSet: false,
      bSet: false,
      cSet: false,
      dset: false,

      teacherId: "",
      selectVal: "d",

      grade: "",

      isDialogOpen: false,
      isSubcategoryDialogOpen: false,
      reload: false,
      subjectId: "",
      defaultSubjectSelectVal: "default",
      defaultTermSelectVal: "default",
      testType: 0,
    });
    this.info = [];
  };

  render() {
    return (
      <div>
        <Helmet>
          <style>{`
           @media print {
                    .table-bordered td, .table-bordered th {
                    border: 1px solid black !important;
                }}         
                `}</style>
        </Helmet>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="tests">Tests</Breadcrumb.Item>
          <Breadcrumb.Item active>New Test</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <div style={{ paddingRight: "30px", paddingBottom: "10px" }}>
            <Button
              style={{ float: "right" }}
              onClick={() => {
                this.save();
              }}
            >
              Save
            </Button>
          </div>
          {/*<div className="row" style={{padding:"15px"}}>
                    <div className="col-sm-3" style={{paddingTop:"10px"}}>
                        <Form.CustomSelect lg mb="3" value={this.state.selectVal} onChange={(v)=>{if(v.target.value!="d"){this.setState({classId:v.target.value,selectVal:v.target.value},this.getStudents);}}}>
                            {this.state.classes.map((x,y) => <option value={x.id} key={y}>{x.class}</option>)}
                            <option value="d" >Choose Class</option>

                        </Form.CustomSelect>

                    </div>
                    <div className="col-sm-9" style={{paddingTop:"10px"}}>
                        <InputGroup className="mb-3" style={{paddingLeft:"10px"}} >
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">Test Topic</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="Class "
                                aria-describedby="inputGroup-sizing-default"
                                value={this.state.topic}
                                onChange={(v)=>{this.setState({topic:v.target.value})}}

                            />
                        </InputGroup>
                    </div>

                </div>
                <div className="row">
                    <div style={{textAlign:"center",padding:"20px"}} >

                        <InputGroup className="mb-3" style={{paddingLeft:"10px"}} >
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">Date</InputGroup.Text>
                            </InputGroup.Prepend>
                            <DatePicker selected={this.state.date} onChange={(val,a)=>{let d=new Date(val).toISOString().split('T')[0];console.log(d);this.setState({date:val,formattedDate:d})}} />
                        </InputGroup>

                    </div>
*/}
          <div
            className="row"
            style={{
              textAlign: "center",
              padding: "15px",
              paddingLeft: "25px",
            }}
          >
            <div className="col-sm-4" style={{ paddingTop: "10px" }}>
              <div style={{ textAlign: "center" }}>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-default">
                      Date
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <DatePicker
                    selected={this.state.date}
                    onChange={(val, a) => {
                      let d = new Date(val).toISOString().split("T")[0];
                      console.log(d);
                      this.setState({ date: val, formattedDate: d });
                    }}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="col-sm-4" style={{ paddingTop: "10px" }}>
              <Form.CustomSelect
                lg
                mb="3"
                value={this.state.defaultTeacherSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    this.setState({
                      teacherId: v.target.value,
                      defaultTeacherSelectVal: v.target.value,
                    });
                  }
                }}
              >
                {this.state.teachers.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.name}
                  </option>
                ))}
                {this.state.defaultTeacherSelectVal == "default" && (
                  <option value="default">Choose Teacher</option>
                )}
              </Form.CustomSelect>
            </div>
            <div className="col-sm-4" style={{ paddingTop: "10px" }}>
              <Form.CustomSelect
                lg
                mb="3"
                value={this.state.defaultGradeSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    this.setState({
                      grade: v.target.value,
                      defaultGradeSelectVal: v.target.value,
                      defaultClassSelectVal: "default",
                    });
                    getClasses(v.target.value, this);
                    console.log(this.state.grade);
                  }
                }}
              >
                {this.state.grades.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.name}
                  </option>
                ))}
                {this.state.defaultGradeSelectVal == "default" && (
                  <option value="default">Choose Grade</option>
                )}
              </Form.CustomSelect>
            </div>
          </div>

          {this.state.grade != "" && (
            <div
              className="row"
              style={{
                textAlign: "center",
                padding: "15px",
                paddingLeft: "25px",
              }}
            >
              <div className="col-sm-3" style={{ paddingTop: "10px" }}>
                <Form.CustomSelect
                  lg
                  mb="3"
                  value={this.state.defaultSubjectSelectVal}
                  onChange={(v) => {
                    if (v.target.value != "default") {
                      this.setState({
                        subjectId: v.target.value,
                        defaultSubjectSelectVal: v.target.value,
                      });
                    }
                  }}
                >
                  {this.state.subjects.map((x, y) => (
                    <option value={x.id} key={y}>
                      {x.name}
                    </option>
                  ))}
                  {this.state.defaultSubjectSelectVal == "default" && (
                    <option value="default">Choose Subject</option>
                  )}
                </Form.CustomSelect>
              </div>

              <div className="col-sm-3" style={{ paddingTop: "10px" }}>
                {this.state.grade != "" && (
                  <Form.CustomSelect
                    lg
                    mb="3"
                    value={this.state.defaultClassSelectVal}
                    onChange={(v) => {
                      if (v.target.value != "default") {
                        this.setState(
                          {
                            classId: v.target.value,
                            defaultClassSelectVal: v.target.value,
                          },
                          setNewTestClass(v.target.value, this)
                        );
                      }
                    }}
                  >
                    {this.state.classes.map((x, y) => (
                      <option value={x.id} key={y}>
                        {x.class}
                      </option>
                    ))}
                    {this.state.defaultClassSelectVal == "default" && (
                      <option value="default">Choose Class</option>
                    )}
                  </Form.CustomSelect>
                )}
              </div>
              <div className="col-sm-6" style={{ paddingTop: "10px" }}>
                <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-default">
                      Test Topic
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    aria-label="Class "
                    aria-describedby="inputGroup-sizing-default"
                    value={this.state.topic}
                    onChange={(v) => {
                      this.setState({ topic: v.target.value });
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          )}
          <div style={{ padding: "15px", paddingLeft: "25px" }}>
            <Form.CustomSelect
              style={{ maxWidth: "300px" }}
              lg
              mb="3"
              value={this.state.defaultTermSelectVal}
              onChange={(v) => {
                if (v.target.value != "default") {
                  this.setState({
                    term: v.target.value,
                    defaultTermSelectVal: v.target.value,
                  });
                }
              }}
            >
              <option value="1">Term 1</option>
              <option value="2">Term 2</option>
              <option value="3">Term 3</option>
              {/*<option value="4">Term 4</option>*/}
              {this.state.defaultTermSelectVal == "default" && (
                <option value="default">Choose Term</option>
              )}
            </Form.CustomSelect>
          </div>
          <div
            onChange={(event) => {
              let v = event.target.value;

              if (v == 0) {
                this.setState({
                  testType: v,
                  aSet: true,
                  bSet: false,
                  cSet: false,
                  dSet: false
                });
              }
              if (v == 1) {
                this.setState({
                  testType: v,
                  bSet: true,
                  aSet: false,
                  cSet: false,
                  dSet: false
                });
              }
              if (v == 2) {
                this.setState({
                  testType: v,
                  cSet: true,
                  aSet: false,
                  bSet: false,
                  dSet: false,
                });
              }
              if (v == 4) {
                this.setState({
                  testType: v,
                  cSet: false,
                  aSet: false,
                  bSet: false,
                  dSet: true,
                });
              }
            }}
            style={{ padding: "15px", paddingLeft: "25px" }}
          >
            <div className="radio">
          <label>
            <input
              name="test_type"
              checked={this.state.dSet}
              type="radio"
              value="4"
            />
            Quiz
          </label>
        </div>
            <div className="radio">
              <label>
                <input
                  name="test_type"
                  checked={this.state.aSet}
                  type="radio"
                  value="0"
                />
                Regular Test
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  name="test_type"
                  checked={this.state.set}
                  type="radio"
                  value="1"
                />
                Midterm
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  name="test_type"
                  checked={this.state.set}
                  type="radio"
                  value="2"
                />
                Final
              </label>
            </div>
          </div>

          {/*</div>*/}
          {this.state.studentData.length > 0 && (
            <div style={{ padding: "15px" }}>
              <Table
                pagination={false}
                selectable={false}
                delete={false}
                switch={false}
                editable={true}
                editVerify={true}
                editFuncVerify={(oldValue, newValue, row, column, done) => {
                  if (column.text == "Notes") {
                    this.info.map((item, index) => {
                      if (item.id == row.id) {
                        item.note = newValue;
                      }
                    });
                  }
                  if (column.dataField == "mark") {
                    if (isNaN(newValue)) {
                      newValue = "";
                      alert("numbers only");
                      done(false);
                      return false;
                    }
                  }
                }}
                columns={this.studentColumns}
                data={this.state.studentData}
              />
              {/*<div>*/}
              {/*    <ReactDataGrid*/}
              {/*        rowKeyGetter={rowKeyGetter}*/}
              {/*        columns={this.studentColumns2}*/}
              {/*        rows={this.state.studentData}*/}
              {/*        rowsCount={this.state.studentData.length}*/}
              {/*        minHeight={150} />*/}

              {/*</div>*/}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default NewTest;

/*
 formatter:(cell,row,rowIndex)=>{return <div>
            <input type="number"
            //value={this.info[rowIndex].mark}

            value={this.state.studentData[rowIndex].mark}
            onChange={(e)=>{
                this.info[rowIndex].mark=parseInt(e.target.value)


                // this.state.studentData.map((item)=>{if(item.id==row.id){item.mark=e.target.value}});

let c=this.state.studentData
                c[rowIndex].mark=e.target.value
                 this.setState({studentData:c},()=>{console.log(c[rowIndex].mark)})


            }}/></div>
        },
 */
