/**
 * Created by Yosef Nussen
 * Date: 6/28/20
 */
import React from "react";
import { CardHeader } from "@material-ui/core";
import {
  Card,
  CardContent,
  CardActions,
  Collapse,
  Typography,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Print from "@material-ui/icons/Print";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import baseUrl, { authentication } from "../Utils";
import ReactToPrint from "react-to-print";

/*const useStyles = {
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: transitions.create('transform', {
            duration: {5},
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
};*/

class Dashboard extends React.Component {
  useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  }));
  classes = this.useStyles;

  handleExpandClick = (panel) => {
    switch (panel) {
      case "test":
        this.setState({ testExpanded: !this.state.testExpanded });
        break;
      case "retake":
        this.setState({ retakeExpanded: !this.state.retakeExpanded });
        break;
      case "privlage":
        this.setState({ privlageExpanded: !this.state.privlageExpanded });
        break;
    }
    // this.setState({expanded:!this.state.expanded});
  };
  handleAssignmentExpanded = () => {
    this.setState({ assignmentExpanded: !this.state.assignmentExpanded });
  };
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      assignments: [],
      assignments1: [],
      assignments2: [],
      missingTests: [],
      missingTests1: [],
      missingTests2: [],
      missingRetakes: [],
      missingRetakes1: [],
      missingRetakes2: [],
      noPrivlages: [],
      noPrivlages1: [],
      noPrivlages2: [],

      assignmentExpanded: false,
      testExpanded: false,
      retakeExpanded: false,
      privlageExpanded: false,
    };
    this.getAssignments();
    this.getTests();
    this.getRetakes();
    this.getNoPrivlages();
  }
  getAssignments = () => {
    fetch(`${baseUrl}/assignments`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        /*console.log(response)
            for (let [key, value] of response.headers) {
                alert(`${key} = ${value}`);
            }*/
        console.log(JSON.stringify(response));
        if (response.headers.login == "login") {
        } else {
          return response.json();
        }
      })
      .then((res) => {
        if (res.login == "login") {
          this.props.history.push({
            pathname: "/login",
          });
        } else {
          // console.log(res)
          this.setState({ assignments: res });
          this.setAssignmentComponents();
        }
      });
  };
  getNoPrivlages = () => {
    fetch(`${baseUrl}/no-privlages`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.login == "login") {
          this.props.history.push({
            pathname: "/login",
          });
        } else {
          this.setState({ noPrivlages: res });
          this.setNoPrivlagesComponents();
        }
      });
  };
  getTests = () => {
    fetch(`${baseUrl}/missing-tests`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.login == "login") {
          this.props.history.push({
            pathname: "/login",
          });
        } else {
          this.setState({ missingTests: res });
          this.setTestComponents();
        }
      });
  };
  getRetakes = () => {
    fetch(`${baseUrl}/missing-retakes`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.login == "login") {
          this.props.history.push({
            pathname: "/login",
          });
        } else {
          this.setState({ missingRetakes: res });
          this.setRetakeComponents();
        }
      });
  };
  setTestComponents = () => {
    let components = [];
    let moreComponents = [];
    let testId = "";
    let testIndex = 0;

    this.state.missingTests.map((test, index) => {
      if (test.missingStudents.length > 0) {
        testIndex++;
        let c;
        let c1;
        c1 = (
          <Typography
            variant="body2"
            component="p"
            style={{ fontWeight: "bold" }}
          >
            {`Class: ${test.class}`}
          </Typography>
        );
        // if (testId != test.id) {
        c = (
          <Typography variant="body2" component="p">
            {`Test: ${test.topic} Term: ${test.term} Subject: ${test.subject} Teacher: ${test.teacher} Date: ${test.date}`}
          </Typography>
        );
        testIndex < 10 ? components.push(c1) : moreComponents.push(c1);
        testIndex < 10 ? components.push(c) : moreComponents.push(c);
        //testId =test.id;
        //  }
        test.missingStudents.map((student) => {
          testIndex++;
          c = (
            <CardHeader
              style={{ padding: 8 }}
              subheader={` ${student.first_name} ${student.last_name}`}
            />
          );

          testIndex < 10 ? components.push(c) : moreComponents.push(c);
        });
      }
    });
    this.setState({ missingTests1: components, missingTests2: moreComponents });
  };

  setRetakeComponents = () => {
    let components = [];
    let moreComponents = [];
    let testId = "";
    let testIndex = 0;

    this.state.missingRetakes.map((test, index) => {
      if (test.missingStudents.length > 0) {
        testIndex++;
        let c;
        let c1;
        c1 = (
          <Typography
            variant="body2"
            component="p"
            style={{ fontWeight: "bold" }}
          >
            {`Class: ${test.class}`}
          </Typography>
        );
        // if (testId != test.id) {
        c = (
          <CardHeader
            subheader={`Test: ${test.topic} Term: ${test.term} Subject: ${test.subject} Teacher: ${test.teacher} Date: ${test.date}`}
          />
        );
        testIndex < 10 ? components.push(c1) : moreComponents.push(c1);
        testIndex < 10 ? components.push(c) : moreComponents.push(c);
        //testId =test.id;
        //  }
        test.missingStudents.map((student) => {
          testIndex++;
          c = (
            <Typography variant="body2" component="p">
              {student.first_name} {student.last_name} Mark: {student.mark}{" "}
              {student.note != "" && ` Notes: ${student.note}`}
            </Typography>
          );
          testIndex < 10 ? components.push(c) : moreComponents.push(c);
        });
      }
    });
    this.setState({
      missingRetakes1: components,
      missingRetakes2: moreComponents,
    });
  };

  setAssignmentComponents = () => {
    let components = [];
    let moreComponents = [];
    let grade = "";

    this.state.assignments.map((kid, index) => {
      let c;
      if (grade != kid.grade) {
        c = <CardHeader subheader={kid.grade + " Grade"} />;
        index < 10 ? components.push(c) : moreComponents.push(c);
        grade = kid.grade;
      }
      c = (
        <Typography variant="body2" component="p">
          {kid.first_name} {kid.last_name}
        </Typography>
      );
      index < 10 ? components.push(c) : moreComponents.push(c);
    });
    this.setState({ assignments1: components, assignments2: moreComponents });
    return components;
  };
  setNoPrivlagesComponents = () => {
    let components = [];
    let moreComponents = [];
    let grade = "";

    this.state.noPrivlages.map((kid, index) => {
      let c;
      if (grade != kid.grade) {
        c = <CardHeader subheader={kid.grade + " Grade"} />;
        index < 10 ? components.push(c) : moreComponents.push(c);
        grade = kid.grade;
      }
      c = (
        <Typography variant="body2" component="p">
          {kid.first_name} {kid.last_name}
        </Typography>
      );
      index < 10 ? components.push(c) : moreComponents.push(c);
    });
    this.setState({ noPrivlages1: components, noPrivlages2: moreComponents });
    return components;
  };

  render() {
    return (
      <div>
        {" "}
        <Breadcrumb>
          <Breadcrumb.Item active="home">Home</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ margin: "30px" }}>
          {/*<Paper elevation={6} style={ {width:"400px"}}>*/}
          {/*    <div  style={{minHeight:"300px"}}>*/}
          {/*        <h3>Assignments</h3>*/}

          {/*    </div>*/}
          {/*</Paper>*/}
          <div className="row">
            <div
              ref={(el) => (this.assignmentDiv = el)}
              className="col-sm-3"
              style={{ padding: "20px", width: "375px" }}
            >
              {
                //this.state.assignments.length>0&&
                <ReactToPrint
                  trigger={() => {
                    // ()=>{this.setState({testExpanded:true})}
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return <a href="#">Print Assignments</a>;
                  }}
                  content={() => this.assignmentDiv}
                />
              }
              <Card elevation={10} className={this.classes.root}>
                <CardHeader
                  title="Assignments"
                  // subheader="class 7.1"
                />

                <CardContent>
                  {
                    [...this.state.assignments1]

                    /*
                       this.state.assignments.map((kid)=><Typography variant="body2" color="textSecondary" component="p">
                       {kid.first_name} {kid.last_name}
                   </Typography>
                   )*/
                  }
                  {/* <Typography variant="body2" color="textSecondary" component="p">

                       This impressive paella is a perfect party dish and a fun meal to cook together with your
                       guests. Add 1 cup of frozen peas along with the mussels, if you like.
                   </Typography>*/}
                </CardContent>
                {/*        <CardHeader
                       // title="Assignments"
                       subheader="class 7.1"
                   />
                   <CardContent>
                   <Typography variant="body2" color="textSecondary" component="p">
                       This impressive paella is a perfect party dish and a fun meal to cook together with your
                       guests. Add 1 cup of frozen peas along with the mussels, if you like.
                   </Typography>
                   </CardContent>
*/}
                <CardActions disableSpacing>
                  {/* <IconButton aria-label="add to favorites">
                       <FavoriteIcon />
                   </IconButton>*/}
                  {/*                   <IconButton aria-label="print">*/}
                  {/*<Print/>*/}
                  {/*                   </IconButton>*/}
                  <IconButton
                    className={clsx(this.classes.expand, {
                      [this.classes.expandOpen]: this.state.assignmentExpanded,
                    })}
                    onClick={this.handleAssignmentExpanded}
                    aria-expanded={this.state.assignmentExpanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>
                <Collapse
                  in={this.state.assignmentExpanded}
                  timeout="auto"
                  unmountOnExit
                >
                  <CardContent>
                    <Typography paragraph>
                      {[...this.state.assignments2]}
                    </Typography>
                    <Typography paragraph></Typography>
                  </CardContent>
                </Collapse>
              </Card>
            </div>

            <div
              ref={(el) => (this.missingTestDiv = el)}
              className="col-sm-3"
              style={{ padding: "20px", width: "375px" }}
            >
              {
                //this.state.missingTests1.length>0&&
                <ReactToPrint
                  trigger={() => {
                    // ()=>{this.setState({testExpanded:true})}
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return <a href="#">Print Missing Test</a>;
                  }}
                  content={() => this.missingTestDiv}
                />
              }
              <Card elevation={10} className={this.classes.root}>
                <CardHeader title="Missing Tests" />

                <CardContent>{[...this.state.missingTests1]}</CardContent>

                <CardActions disableSpacing>
                  {/*<IconButton ref={el => (this.printTest = el)} aria-label="print">*/}
                  {/*    <Print/>*/}
                  {/*</IconButton>*/}
                  <IconButton
                    className={clsx(this.classes.expand, {
                      [this.classes.expandOpen]: this.state.testExpanded,
                    })}
                    onClick={() => {
                      this.handleExpandClick("test");
                    }}
                    aria-expanded={this.state.expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>
                <Collapse
                  in={this.state.testExpanded}
                  timeout="auto"
                  unmountOnExit
                >
                  <CardContent>
                    <Typography paragraph>
                      {[...this.state.missingTests2]}
                    </Typography>
                    <Typography paragraph></Typography>
                  </CardContent>
                </Collapse>
              </Card>
            </div>

            <div
              ref={(el) => (this.retakeDiv = el)}
              className="col-sm-3"
              style={{ padding: "20px", width: "375px" }}
            >
              {
                //this.state.missingRetakes.length>0&&
                <ReactToPrint
                  trigger={() => {
                    // ()=>{this.setState({testExpanded:true})}
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return <a href="#">Print Retakes</a>;
                  }}
                  content={() => this.retakeDiv}
                />
              }
              <Card elevation={10} className={this.classes.root}>
                <CardHeader title="Must Retake Test" />

                <CardContent>{[...this.state.missingRetakes1]}</CardContent>

                <CardActions disableSpacing>
                  {/*<IconButton aria-label="print">*/}
                  {/*    <Print/>*/}
                  {/*</IconButton>*/}
                  <IconButton
                    className={clsx(this.classes.expand, {
                      [this.classes.expandOpen]: this.state.retakeExpanded,
                    })}
                    onClick={() => {
                      this.handleExpandClick("retake");
                    }}
                    aria-expanded={this.state.retakeExpanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>
                <Collapse
                  in={this.state.retakeExpanded}
                  timeout="auto"
                  unmountOnExit
                >
                  <CardContent>
                    <Typography paragraph> </Typography>
                    <Typography paragraph>
                      {[...this.state.missingRetakes2]}
                    </Typography>
                  </CardContent>
                </Collapse>
              </Card>
            </div>
            <div
              ref={(el) => (this.privlageDiv = el)}
              className="col-sm-3"
              style={{ padding: "20px", width: "375px" }}
            >
              {
                //this.state.noPrivlages.length>0&&

                <ReactToPrint
                  trigger={() => {
                    // ()=>{this.setState({testExpanded:true})}
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return <a href="#">Print No Privileges</a>;
                  }}
                  content={() => this.privlageDiv}
                />
              }
              <Card elevation={10} className={this.classes.root}>
                <CardHeader title="No Privileges" />

                <CardContent>{[...this.state.noPrivlages1]}</CardContent>

                <CardActions disableSpacing>
                  {/*<IconButton aria-label="print">*/}
                  {/*    <Print/>*/}
                  {/*</IconButton>*/}
                  <IconButton
                    className={clsx(this.classes.expand, {
                      [this.classes.expandOpen]: this.state.privlageExpanded,
                    })}
                    onClick={() => {
                      this.handleExpandClick("privlage");
                    }}
                    aria-expanded={this.state.privlageExpanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>
                <Collapse
                  in={this.state.privlageExpanded}
                  timeout="auto"
                  unmountOnExit
                >
                  <CardContent>
                    {[...this.state.noPrivlages2]}
                    {/*  <Typography paragraph>We Add More Here</Typography>
                               <Typography paragraph>

                               </Typography>*/}
                  </CardContent>
                </Collapse>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Dashboard;
