import React from "react";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import { Type } from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Form } from "bootstrap-4-react";
import Select from "react-select";
import baseUrl, { authentication, getGrades, getTeachers } from "../Utils";
import { FaCheck } from "react-icons/fa";
import { Helmet } from "react-helmet";

const columnGroups = [
  {
    headerRow: '#81c784',
    formatFunc: 'this.formatGreenRow',
    periodId: 1
  },
  {
    headerRow: '#FF9999',
    formatFunc: 'this.formatRedRow',
    periodId: 2
  },
  { 
    headerRow: '#72b6ed',
    formatFunc: 'this.formatBlueRow',
    periodId: 3
  }
];

const options = [
  {
    value: 2,
    label: " 2",
  },
  {
    value: 1,
    label: " 1",
  },
  {
    value: 0,
    label: " 0",
  },
  { value: "-1", label: "-1" },
  { value: "-2", label: "-2" },
  { value: "-3", label: "-3" },
  { value: "-4", label: "-4" },
  { value: "-5", label: "-5" },
];
class SHAGNewBehavior extends React.Component {
  info = [];
  reset = () => {
    this.setState({
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",
      defaultTeacherSelectVal: "default",
      classId: "",
      teacherId1: "",
      teacherId2: "",
      teacherId3: "",
      studentPoints: new Map(),

      grade: "",

      classes: [],
    });
  };
  constructor(props) {
    super(props);
    this.state = {
      classId: "",
      settings: {},
      teacherId1: "",
      teacherId2: "",
      teacherId3: "",
      date: "",
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",
      defaultTeacherSelectVal: "default",
      teachers: [],
      formattedDate: "",

      studentPoints: new Map(),
      grades: [],
      grade: "",
      editing: false,

      classes: [],
      newColumns: [],
        
    };
    //this.getClasses()
    this.getSettings();
    getGrades(this);
    getTeachers(this);
    //this.getStudents()
  }

  componentDidMount() {
    this.setupColumns();
  }

  setupColumns() {
    console.log(this.state);
    let columns = [
      {
        dataField: "first_name",
        text: "First Name",
        sort: true,
        editable: false,
      },
      {
        dataField: "last_name",
        text: "Last Name",
        sort: true,
        editable: false,
      },
    ];

    for (let i=1; i<= 3; i++) {
      columns = columns.concat(this.setupColumnsGroups(i));
    }
    columns.push({
      headerStyle: (colum, colIndex) => {
        return { width: "30%", textAlign: "center" };
      },
      dataField: "note",
      text: "Notes",
      editor: {
        type: Type.TEXTAREA,
      },
      attrs: {
        'data-period-id': 1
      }
    });

    this.setState({newColumns: columns});

  }

  setupColumnsGroups(periodId) {
    return [
    {
      dataField: "class_work"+periodId,
      text: "Class Work Period "+periodId,
      editable: (this.state['teacherId'+periodId] ? true : false),
      headerStyle: {
        backgroundColor: columnGroups[periodId -1].headerRow
      },
      align: 'center',
      style: eval(columnGroups[periodId -1].formatFunc),
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          console.log(`current editing row id: ${row.id}`);
          console.log(`current editing column: ${column.dataField}`);
          const { marks_low, marks_high } = this.state.settings;
          let range = [];
          for (let i = marks_low; i <= marks_high; i++) {
            range.push({ value: i, label: `${i}` });
          }
          return range;
        },
      },
      attrs: {
        'data-period-id': periodId
      }
    },
    {
      dataField: "absent"+periodId,
      align: 'center',
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return [
            { value: "unexcused", label: "unexcused" },
            { value: "absent", label: "absent" },
            { value: "present", label: "present" },
          ];
        },
      },
      text: "Absent Period "+periodId,
      editable: (this.state['teacherId'+periodId] ? true : false),
      headerStyle: {
        backgroundColor: columnGroups[periodId -1].headerRow
      },
      style: eval(columnGroups[periodId -1].formatFunc),
      attrs: {
        'data-period-id': periodId
      }
    },
    {
      dataField: "late"+periodId,
      text: "Late Period "+periodId,
      align: 'center',
      editable: (this.state['teacherId'+periodId] ? true : false),
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          console.log(`current editing row id: ${row.id}`);
          console.log(`current editing column: ${column.dataField}`);
          const { late_low, late_high } = this.state.settings;
          let range = [];
          for (let i = late_low; i <= late_high; i++) {
            range.push({ value: i, label: `${i}` });
          }
          return range;
        },
      },
      headerStyle: {
        backgroundColor: columnGroups[periodId-1].headerRow
      },
      style: eval(columnGroups[periodId-1].formatFunc),
      attrs: {
        'data-period-id': periodId
      }
    }];
  }

  formatGreenRow(cell, row, rowIndex, colIndex) {
    if (rowIndex % 2 === 0) {
      return {
        backgroundColor: '#89c498'
      };
    }
    return {      
      backgroundColor: '#7DDE95'  
    };
  }
  formatRedRow(cell, row, rowIndex, colIndex) {
    if (rowIndex % 2 === 0) {
      return {
        backgroundColor: '#dba9a9'  
      };
    }
    return {      
      backgroundColor: '#FFCCCC'
    };
  }
  formatBlueRow(cell, row, rowIndex, colIndex) {
    if (rowIndex % 2 === 0) {
      return {
        backgroundColor: '#9abcd9'
      };
    }
    return {      
      backgroundColor: '#afd8fa'  
    };
  }

  getSettings() {
    fetch(`${baseUrl}/settings`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({
          settings: res.reduce(
            (pre, cur) => ({ ...pre, [cur.setting]: cur.value }),
            {}
          ),
        });
      });
  }
  // getClasses(){
  //     var url2 = new URL("http://ohrhatorah.appsenses.com/getClasses.php")
  //     fetch(url2).then(response=>{ return response.json();}).then(res=>{
  //         res.forEach((a)=>{a.id=parseInt(a.id)})
  //         this.setState({classes:res})
  //
  //     })
  // }
  getClasses(grade) {
    fetch(`${baseUrl}/grade/${grade}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ classes: res });
      });
  }
  getOldDay(classId) {
    let date = this.state.formattedDate;
    if (date && date != ""  && classId != "") {
      fetch(
        `${baseUrl}/daily-marks-with-period/${classId}/${date}`,
        {
          headers: {
            Authorization: authentication,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          res.forEach((a) => {
            a.id = parseInt(a.id);
          });
          if (res.length > 0) {
            this.setState({
              studentData: res,
              editing: true,
             /* work_given: res[0].work_name,
              work_given_2: res[0].work_name_2,
              work_given_3: res[0].work_name_3, */
            });
            this.setState({teacherId1: (res[0].teacher_id1 || "") });
            this.setState({teacherId2: (res[0].teacher_id2 || "") });
            this.setState({teacherId3: (res[0].teacher_id3 || "") });
          } else {
            this.setState({ editing: false }, this.setClass);
          }
        })
        .then(() => {
          this.setupColumns();
        });
    }
  }
  updateNote(i, n) {
    fetch(baseUrl + "/update-student-day-note/" + i + "/" + n, {
      headers: {
        Authorization: authentication,
      },
    }).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  updateLate(marksId, oldMarks, newMarks) {
    fetch(
      baseUrl +
        "/update-student-day-late/" +
        marksId +
        "/" +
        newMarks +
        "/" +
        oldMarks,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then(() => {
      this.getOldDay(this.state.classId);
    });
  }

  updateMarks(marksId, oldMarks, newMarks) {
    fetch(
      baseUrl +
        "/update-student-day-marks/" +
        marksId +
        "/" +
        newMarks +
        "/" +
        oldMarks,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  updateAbsent(marksId, status) {
    fetch(baseUrl + "/update-student-day-absence/" + marksId + "/" + status, {
      headers: {
        Authorization: authentication,
      },
    }).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  updateWork(marksId, oldMarks, newMarks, number) {
    if (!this.state[`work_given${number}`]) return;
    fetch(
      `${baseUrl}/update-student-day-work/${marksId}/${newMarks}${
        number ? `/${number}` : ""
      }`,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  updateWorkGiven(number) {
    if (!this.state[`work_given${number || ""}`]) return;

    fetch(
      `${baseUrl}/update-work-name/${this.state.formattedDate}/${
        this.state[`work_given${number || ""}`]
      }${number ? `/${number}` : ""}`,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  updateNoWorkGiven(number) {
    fetch(
      `${baseUrl}/update-day-no-work/${this.state.formattedDate}${
        number ? `/${number}` : ""
      }`,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  /*getGrades(){
            fetch(baseUrl+"/grades").then(response=>{ return response.json();}).then(res=>{

                res.forEach((a)=>{a.id=parseInt(a.id)})
                this.setState({grades:res})
            })
    }*/
  getTeachers() {
    fetch(baseUrl + "/teachers", {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ teachers: res });
      });
  }
  setClass() {
    let formData = new FormData();
    formData.append("action", "get class");
    formData.append("id", this.state.classId);
    var url = `${baseUrl}/bare-class/${this.state.classId}`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
          item.absent = "";
          item.class_work1 = "";
          item.absent1 = "";
          item.late1 = "";
          item.class_work2 = "";
          item.absent2 = "";
          item.late2 = "";
          item.class_work3 = "";
          item.absent3 = "";
          item.late3 = "";
          item.note = "";

          this.info.push(item);
        });
        this.setState({
          studentData: res,
          work_given: "",
          work_given_2: "",
          work_given_3: "",
          teacherId1: "",
          teacherId2: "",
          teacherId3: "",
        });
      });
  }
  print() {
    let formData = new FormData();
    formData.append("action", "get class");
    formData.append("id", this.state.classId);
    var url = "http://ohrhatorah.appsenses.com/students.php";
    url = `${baseUrl}/bare-class/${this.state.classId}`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
          item.absent = "";
          item.behavior = "";
          item.work = "";
          item.work_2 = "";
          item.work_3 = "";
          item.late = "";
          item.note = "";

          this.info.push(item);
        });
        this.setState({ studentData: res }, () => {
          window.print();
        });
      });
  }

  // absentFormatter = (cell, row, rowIndex) => {
  //   let data = this.state.studentData;
  //   console.log(row);
  //   data.map((d) => {
  //     if (d.id == row.id) {
  //       d.absent = !d.absent;
  //     }
  //   });


  validate = () => {
    if (this.state.formattedDate == "") {
      alert("Please select a date");
    } else if (this.state.teacherId1 == "") {
      alert("Please select a teacher");
    } else {
      return true;
    }
    return false;
  };

  formatData(data, periodId) {
    let isDefault = true;
    const classwork = 'class_work'+periodId;
    const absent = 'absent'+periodId;
    const late = 'late'+periodId;
    const studentData = {
      studentId: data.id,
      absent: data[absent] ? data[absent] : 'present',
      late: (+data[late] || +data[late]  === 0
      ? +data[late] 
      : +this.state.settings.late_high || 0)
    };

    if ((data[absent] === "present"  || !(data[absent]))
        && data[classwork]
        && data[classwork] < this.state.settings.marks_default) {
        isDefault = false;
    }
    if (data[classwork] || data[classwork] === 0 ){
      studentData.marks = +data[classwork];
    } else {
      if (data[classwork] != "absent") {
        studentData.marks = "D";
      }
    }

    if (periodId === 1) {
      //add notes just for period ID 1, because although we can store it per period, we only display it once
      studentData.note = data.note;
    }
    
    return {data: studentData, stillDefault: isDefault}
  }

  save = () => {
    if (this.validate()) {
      let dataPeriods = []

      columnGroups.forEach((column) => {
        if (this.state['teacherId'+column.periodId]) {
          dataPeriods.push({
            periodId: column.periodId,
            teacherId: this.state['teacherId'+column.periodId],
            studentData: [],
            classDefaults: true
          });
        }
      });

      this.state.studentData.forEach((student) => {
        dataPeriods.forEach((dp, i) => {
          const {data, stillDefault} = this.formatData(student, dp.periodId);
          dataPeriods[i].classDefaults = (dataPeriods[i].classDefaults && stillDefault);
          dataPeriods[i].studentData.push(data);
        });
      });

      let date = this.state.formattedDate;
      let students = {
        dataPeriods,
        date,
        class: this.state.classId
      };
      students = JSON.stringify(students);

      let formData = new FormData();
      formData.append("data", students);

      ///${this.state.teacherId}/${date}/${this.state.classId}
      fetch(`${baseUrl}/shag-new-daily-marks2`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())

        .then((text) => {
          students = "";
          console.log(text);

          if (text == "successful") {
            this.reset();
          } else {
            alert("error" + text);
          }
        }); 
    }
  };

  render() {
    return (
      <div>
        <Helmet>
          <style>{`
           @media print {
                    .table-bordered td, .table-bordered th {
                    border: 1px solid black !important;
                }}         
                `}</style>
        </Helmet>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>

          <Breadcrumb.Item active>New Day</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          {!this.state.editing && (
            <div style={{ paddingRight: "30px", paddingBottom: "10px" }}>
              <Button
                style={{ float: "right" }}
                onClick={() => {
                  this.save();
                }}
              >
                Save
              </Button>
              <Button
                style={{ float: "right", marginRight: "15px" }}
                onClick={() => {
                  this.print();
                }}
              >
                Print
              </Button>
            </div>
          )}
          <div
            className="row"
            style={{
              textAlign: "center",
              padding: "15px",
              paddingLeft: "25px",
            }}
          >
            <div
              className="col-sm-12 col-lg-4 col-xl-3"
              style={{ paddingTop: "10px" }}
            >
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Date
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  selected={this.state.date}
                  onChange={(val, a) => {
                    let d = new Date(val).toISOString().split("T")[0];
                    console.log(d);
                    this.setState({ date: val, formattedDate: d }, () => {
                      if (this.state.classId != "") {
                        this.getOldDay(this.state.classId);
                      }
                    });
                  }}
                />
              </InputGroup>
            </div>
            <div
              className="col-sm-12 col-lg-4 col-xl-5"
              style={{ paddingTop: "10px" }}
            >
              <Form.CustomSelect
                lg
                mb="3"
                value={this.state.defaultGradeSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    this.setState({
                      grade: v.target.value,
                      defaultGradeSelectVal: v.target.value,
                      defaultClassSelectVal: "default",
                    });

                    this.getClasses(v.target.value);
                    console.log(this.state.grade);
                  }
                }}
              >
                {this.state.grades.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.name}
                  </option>
                ))}
                {this.state.defaultGradeSelectVal == "default" && (
                  <option value="default">Choose Grade</option>
                )}
              </Form.CustomSelect>
            </div>

            <div className="col-sm-6" style={{ paddingTop: "10px" }}>
              {this.state.grade != "" && (
                <Form.CustomSelect
                  lg
                  mb="3"
                  value={this.state.defaultClassSelectVal}
                  onChange={(v) => {
                    if (v.target.value != "default") {
                      this.setState(
                        {
                          classId: v.target.value,
                          defaultClassSelectVal: v.target.value,
                        },
                        this.getOldDay(v.target.value)
                      );
                    }
                  }}
                >
                  {this.state.classes.map((x, y) => (
                    <option value={x.id} key={y}>
                      {x.class}
                    </option>
                  ))}
                  {this.state.defaultClassSelectVal == "default" && (
                    <option value="default">Choose Class</option>
                  )}
                </Form.CustomSelect>
              )}
            </div>
          </div>
          {this.state.studentData.length > 0 && (
            <>
              <div
                style={{
                  width: "60%",
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: "12%"
                }}
              >
                {
                  columnGroups.map((col) => {
                    return <div style={{backgroundColor: col.headerRow, width:'30%'}}>
                      <span style={{fontWeigh: 'bold'}}>Period {col.periodId}</span>
                      <Form.CustomSelect 
                        lg  
                        mb="3"
                        value={this.state['teacherId'+col.periodId]}
                        onChange={(v) => {
                          if (v.target.value) {
                            let obj = {}
                            obj['teacherId'+col.periodId] = v.target.value;
                            this.setState(obj, this.setupColumns);
                        }
                      }} 
                      >
                      {!this.state['teacherId'+col.periodId] && !this.state['teacherId'+col.periodId].length && (
                        <option value="default">Choose Teacher</option>
                      )}
                      {this.state.teachers.map((x, y) => (
                        <option value={x.id} key={y}>
                          {x.name}
                      </option>
                      ))}
                    </Form.CustomSelect>
                   </div>
                  })
                }
                
                
              </div>

              <div style={{ padding: "15px" }}>
                <Table
                  pagination={false}
                  search={true}
                  selectable={false}
                  delete={false}
                  switch={false}
                  editable={true}
                  editFunc={(oldValue, newValue, row, { text, dataField, attrs }) => {
                    const periodId = attrs['data-period-id'];
                    if (text == "Notes") {
                      if (this.state.editing) {
                        this.updateNote(row['mark_id1'], newValue);
                      }

                      this.info.map((item, index) => {
                        if (item.id == row.id) {
                          item.note = newValue;
                        }
                      });
                    }
                    if (dataField.startsWith("class_work") && this.state.editing) {
                      this.updateMarks(row['mark_id'+periodId], oldValue, newValue);
                    }
                    if (dataField.startsWith("late") && this.state.editing) {
                      this.updateLate(row['mark_id'+periodId], oldValue, newValue);
                    }
                    if (dataField.startsWith("absent") && this.state.editing) {
                      this.updateAbsent(row['mark_id'+periodId], newValue);
                    }
                  }}
                  columns={this.state.newColumns}
                  data={this.state.studentData}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default SHAGNewBehavior;
