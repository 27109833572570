import React from "react";

import Button from "react-bootstrap/Button";
import Table from "./Table";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import { Type } from "react-bootstrap-table2-editor";
import Chart from "react-google-charts";
import baseUrl, { authentication } from "../Utils";
import { updateChannel } from "../Utils";
import NewClass from "./newClass";

const columns = [
  /*{
    dataField: 'id',
    text: 'Id',
    sort: true
},*/ {
    headerStyle: (colum, colIndex) => {
      return { width: "90%", textAlign: "center" };
    },
    dataField: "name",
    text: "Grades",
    sort: true,
  },
];
class Grades extends React.Component {
  constructor(props) {
    super(props);

    this.state = { possibleGrades: [], grades: [], newChannelOpen: false };
    this.setGrades();
    this.getPossibleGrades();
  }
  setGrades() {
    fetch(baseUrl + "/grades", {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ grades: res });
      });
  }
  getPossibleGrades() {
    fetch(baseUrl + "/possible-grades", {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        let grd = [];
        res.forEach((a) => {
          a.id = parseInt(a.id);
          grd.push({ value: a.id, label: a.name });
        });
        this.setState({ possibleGrades: grd });
      });
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Grades</Breadcrumb.Item>
        </Breadcrumb>
        <NewClass
          afterSaveFunction={() => {
            this.setGrades();
          }}
          possibleGrades={this.state.possibleGrades}
          isDialogOpen={this.state.newChannelOpen}
          closeFunction={() => {
            this.setState({ newChannelOpen: false });
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ padding: "25px", width: "100%", maxWidth: "1000px" }}>
            <div
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                textAlign: "right",
              }}
            >
              <Button
                onClick={() => {
                  this.setState({ newChannelOpen: true });
                }}
              >
                New Class
              </Button>
            </div>
            <Table
              delete={false}
              switch={false}
              columns={columns}
              data={this.state.grades}
              editFunc={(oldValue, newValue, row, column) => {
                updateChannel(oldValue, newValue, row, column);
              }}
              rowEvents={{
                onClick: (e, row, rowIndex) => {
                  console.log(e);
                  if (e.type == "click") {
                    //this.props.stateFunc(row.image)
                    this.props.history.push({
                      pathname: "/grade-details",
                      state: { grade: row.name, gradeId: row.id },
                    });
                  }
                },
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Grades;
