import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import baseUrl, { authentication } from "../Utils";
import Barcode from "react-barcode";

/**
 * Created by Yosef Nussen
 * Date: 12/16/20
 */

class ItemBarcodes extends React.Component {
  constructor() {
    super();
    this.state = { items: [] };
    this.getItems();
  }
  getItems() {
    fetch(`${baseUrl}/all-items`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({ items: res });
      });
  }
  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Item Barcodes</Breadcrumb.Item>
        </Breadcrumb>
        <div className={"row"} style={{ textAlign: "center" }}>
          {this.state.items.length > 0 &&
            this.state.items.map((item, i) => {
              return (
                <div
                  style={{ paddingLeft: "200px", paddingTop: "200px" }}
                  className={"col-xs-6"}
                >
                  <Barcode
                    value={"item" + item.id + " " + item.name}
                    width={1}
                    height={125}
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
export default ItemBarcodes;
