import React from "react";
import Table from "./Table";
import { FaChevronDown, FaCheck, FaTimes } from "react-icons/fa";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import Button from "react-bootstrap/Button";

class Lessons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentData: [],
    };
    this.getLessons();
    //this.getPoints()
  }
  studentColumns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "topic",
      text: "topic",
      sort: true,
    },
    {
      dataField: "class",
      text: "Class",
      sort: true,
    },
  ];

  getLessons() {
    let formData = new FormData();
    formData.append("action", "get lessons");
    var url = "http://ohrhatorah.appsenses.com/lesson.php";

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
        });
        this.setState({ studentData: res });
      });
  }

  getPoints() {
    let formData = new FormData();
    formData.append("action", "get points");
    formData.append("id", this.state.studentId);
    var url = "http://ohrhatorah.appsenses.com/bank.php";

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({ points: res[0].points });
      });
  }

  defaultSorted = [
    {
      dataField: "date", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Lessons</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ padding: "25px" }}>
          <div style={{ paddingBottom: "25px", textAlign: "right" }}>
            <Button
              onClick={() => {
                this.props.history.push({
                  pathname: "/new-lesson",
                });
              }}
            >
              New Lesson
            </Button>
          </div>

          {
            <Table
              delete={false}
              switch={false}
              defaultSorted={this.defaultSorted}
              ref={(n) => (this.existingSubcategoryTableRef = n)}
              columns={this.studentColumns}
              reload={this.state.reload}
              data={this.state.studentData}
              deleteClick={this.deleteSubFromChannel}
              rowEvents={{
                onClick: (e, row, rowIndex) => {
                  if (e.type == "click") {
                    this.props.history.push({
                      pathname: "/lesson-details",
                      state: {
                        lessonId: row.id,
                        topic: row.topic,
                        date: row.date,
                      },
                    });
                  }
                },
              }}
            />
          }
        </div>
      </div>
    );
  }
}
const CollapsibleText = (props) => {
  {
    /*className='col-sm-3 col-lg-2'*/
  }
  {
    /*className='col-sm-9 col-lg-10'*/
  }
  return (
    <span style={{ paddingLeft: "15px" }} className="row">
      <span style={{ width: "250px" }}> {props.text}</span>{" "}
      <span>
        <FaChevronDown />
      </span>
    </span>
  );
};
export default Lessons;
